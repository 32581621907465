import * as React from 'react';
import "../footer/footer.css";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import footerLogo from "./../../images/footer-logo.png";
import googlePlayStore from "./../../images/google-play-store.svg";
import applePlayStore from "./../../images/apple-play-store.svg";
import { Link } from '@mui/material';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';




interface FooterComponentProps {

}

interface FooterComponentState {

}

class FooterComponent extends React.Component<FooterComponentProps, FooterComponentState> {
    constructor(props: FooterComponentProps) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <React.Fragment>
                <footer className='mt-16'>
                    <Box sx={{ flexGrow: 1 }} className='footer'>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant='h4' className='footer-heading'>About Astroconsult</Typography>
                                <Typography variant='body1' className='f-about-dtl'>Astroconsult is the best astrology website for online Astrology predictions. Get best future predictions related to Marriage, love life, Career or Health over call, chat, query or report.</Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item md={3}>
                                        <div className='footer-logos'>
                                            <Link href="/"><img src={footerLogo} alt='footer logo' width={185} height={134} style={{ maxWidth: "100%", height: "auto" }} /></Link>
                                            <div className='footer-playStore'>
                                                <Link href="/">
                                                    <img src={googlePlayStore} alt='footer logo' width={224} height={66} />
                                                </Link>
                                                <Link href="/">
                                                    <img src={applePlayStore} alt='footer logo' width={224} height={70} />
                                                </Link>

                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item md={3}>
                                        <div className='f-navbar'>
                                            <Typography variant='h4' className='footer-heading'>Horoscope</Typography>
                                            <ul>
                                                <li><Link href="/">Horoscope 2024</Link></li>
                                                <li><Link href="/">Horoscope 2023</Link></li>
                                                <li><Link href="/">Today's Horoscope</Link></li>
                                            </ul>
                                        </div>
                                    </Grid>
                                    <Grid item md={3}>
                                        <div className='f-navbar'>
                                            <Typography variant='h4' className='footer-heading'>Astroconsult services</Typography>
                                            <ul>
                                                <li><Link href="/">Today's Horoscope</Link></li>
                                                <li><Link href="/">Free Kundali</Link></li>
                                                <li><Link href="/">Match-Making</Link></li>
                                                <li><Link href="/">Today's panchang</Link></li>
                                                <li><Link href="/marriage-compatibility">Marriage compatibility</Link></li>
                                            </ul>
                                        </div>
                                    </Grid>
                                    <Grid item md={3}>
                                        <div className='f-navbar'>
                                            <Typography variant='h4' className='footer-heading'>Corporate Info</Typography>
                                            <ul>
                                                <li><Link href="/privacy-policy">Privacy Policy</Link></li>
                                                <li><Link href="/term-and-conditions">Terms and Condition</Link></li>
                                                <li><Link href="/about-us">About Us</Link></li>
                                            </ul>
                                        </div>
                                        <div className='f-navbar'>
                                            <Typography variant='h4' className='footer-heading'>Contact Us</Typography>
                                            <ul>
                                                <li><span><FmdGoodIcon /></span> Address: Jaipur - 226030. India</li>
                                                <li><span><CallIcon /></span> Phone: +91 99502 92498</li>
                                                <li><span><EmailIcon /></span> Email ID: info@astroconsult.live</li>
                                            </ul>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{ flexGrow: 1 }} className='footer-bottom'>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <p>Copyright &copy; 2023 AstroConsult Services </p>
                            </Grid>
                        </Grid>
                    </Box>
                </footer>
            </React.Fragment>
        );
    }
}

export default FooterComponent;