import * as React from 'react';
import "./../astrologyVideo-list/astrologyVideoList.css";
import HorizantalTopBanner from '../appBanners/HorizantalTopBanner';
import SidebarCardComponent from '../sidebarCard/SidebarCardComponent';
import VerticalAppBanner from '../appBanners/VerticalAppBanner';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Breadcrumbs, Link, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import videoThumb from "../../images/astrology-video-thumb.png";
import YouTubeIcon from '../../icons/youtube-rectangle.svg';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Sheet from '@mui/joy/Sheet';
import { CardMedia } from '@mui/material';
import videoPath from './../../images/movie.mp4';


interface AstrologyVideoListProps {

}

interface AstrologyVideoListState {
    title: String;
    open: boolean;
    astroVideoList: Array<AstroVideoList>;
}
interface AstroVideoList {
    thumbImg: any;
    thumbTitle: any;
    title: String;
}


class AstrologyVideoList extends React.Component<AstrologyVideoListProps, AstrologyVideoListState> {
    constructor(props: AstrologyVideoListProps) {
        super(props);
        this.state = {
            title: "Astrology Videos",
            open: false,
            astroVideoList: [
                { thumbImg: videoThumb, thumbTitle: "astro", title: "Lorem Ipsum is simply dummy text of the printing and typesetting industry printing." },
                { thumbImg: videoThumb, thumbTitle: "astro", title: "Lorem Ipsum is simply dummy text of the printing and typesetting industry printing." },
                { thumbImg: videoThumb, thumbTitle: "astro", title: "Lorem Ipsum is simply dummy text of the printing and typesetting industry printing." },
                { thumbImg: videoThumb, thumbTitle: "astro", title: "Lorem Ipsum is simply dummy text of the printing and typesetting industry printing." },
            ]
        };
    }
    handleOpenModal = (e: { preventDefault: () => void; }) => {
        e.preventDefault()
        this.setState({ open: true })
    }

    handleCloseModal = (event: any, reason: string) => {
        event.preventDefault();
        if (reason !== 'backdropClick') {
            this.setState({
                open: false
            })
        }
    }
    render() {
        return (
            <React.Fragment>
                <main>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item md={9} xs={12}>
                                <HorizantalTopBanner />
                                <Box sx={{ width: '100%' }} className='heading custom-breadcrumb mt-16'>
                                    <Breadcrumbs separator={<NavigateNextIcon />} aria-label="breadcrumb">
                                        <Link href="/home">Home</Link>
                                        <Typography>Astrology Videos </Typography>
                                    </Breadcrumbs>
                                </Box>
                                <Box className='astrovideo-list-wrap'>
                                    <Typography variant='h4' className='heading-title'>{this.state.title}</Typography>
                                    <Grid container spacing={2}>
                                        {
                                            this.state.astroVideoList.map((videoLists, index) => {
                                                return <Grid item md={4} sm={3} xs={12} key={index}>
                                                    <Card className='astro-video-card'>
                                                        <CardContent className='astro-video-card-content'>
                                                            <Link onClick={this.handleOpenModal}>
                                                                <div className='astro-video-thumb'>
                                                                    <img src={videoLists.thumbImg} alt={videoLists.thumbTitle} width={350} height={223} />
                                                                    <div className='card-overlay'>
                                                                        <img src={YouTubeIcon} alt="youtube icon" width={54} height={35} />
                                                                    </div>
                                                                </div>
                                                                <div className='astro-video-cardbody'>
                                                                    <h6 className='blog-card-title'>{videoLists.title}</h6>
                                                                </div>
                                                            </Link>
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                            })
                                        }
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <SidebarCardComponent />
                                <VerticalAppBanner />
                            </Grid>
                        </Grid>
                    </Box>
                    {/* Video modal box */}
                    <Grid container spacing={2}>
                        <Grid item md={12}>
                            <Modal open={this.state.open} onClose={this.handleCloseModal}
                                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                            >
                                <Sheet variant="plain" sx={{ maxWidth: 700, width: "100%", boxShadow: 'lg', borderRadius: 'md', border: "none" }}>
                                    <ModalClose variant="plain" className="modal-closebtn" />
                                    <div id="modal-desc">
                                        <CardMedia component='video' image={videoPath} autoPlay controls />
                                    </div>
                                </Sheet>
                            </Modal>
                        </Grid>
                    </Grid>
                </main>
            </React.Fragment>
        );
    }
}

export default AstrologyVideoList;