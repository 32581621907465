import * as React from 'react';
import Slider from "react-slick";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { NextArrow, PrevArrow } from "./../slick-arrow/SlickCarousalArrow";
import AstrologyVideoCard from './AstrologyVideoCard';

interface AstrologyCarousalProps {

}

interface AstrologyCarousalState {

}

class AstrologyCarousal extends React.Component<AstrologyCarousalProps, AstrologyCarousalState> {
    constructor(props: AstrologyCarousalProps) {
        super(props);
        this.state = {};
    }
    render() {
        const Carouselsettings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 4,
            initialSlide: 0,
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />,
            variableWidth: true,
            swipeToSlide: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <Box sx={{ flexGrow: 1 }} className='blog-section mt-16'>
                <Typography variant='h4' className='heading-wtbg'>Astrology Videos</Typography>
                <Grid container spacing={2}>
                    <Grid item md={12} style={{ overflow: "hidden" }}>
                        <Slider {...Carouselsettings}>
                            <AstrologyVideoCard />
                            <AstrologyVideoCard />
                            <AstrologyVideoCard />
                            <AstrologyVideoCard />
                            <AstrologyVideoCard />
                            <AstrologyVideoCard />
                        </Slider>
                    </Grid>
                </Grid>
            </Box>
        );
    }
}

export default AstrologyCarousal;