import * as React from 'react';
import "../sidebarCard/sidebarCard.css";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import todayPanchang from "./../../icons/om.svg";
import horoscope from "./../../icons/horoscope.svg";
import astroProduct from "./../../icons/astro.svg";
import wallet from "./../../icons/wallet.svg";
import { Link } from '@mui/material';

interface SidebarCardComponentProps {

}

interface SidebarCardComponentState {

}

class SidebarCardComponent extends React.Component<SidebarCardComponentProps, SidebarCardComponentState> {
    constructor(props: SidebarCardComponentProps) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <React.Fragment>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <div className='sidebar-services'>
                                <ul>
                                    <li>
                                        <Link href="">
                                            <img src={todayPanchang} alt='' width={40} height={40} />
                                            <span> Today's Panchang </span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link href="">
                                            <img src={horoscope} alt='' width={40} height={40} />
                                            <span> Daily Horoscope </span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link href="">
                                            <img src={astroProduct} alt='' width={40} height={40} />
                                            <span>Astro Product</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link href="">
                                            <img src={wallet} alt='' width={40} height={40} />
                                            <span>recharge wallet</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </Grid>
                    </Grid>
                </Box>
            </React.Fragment>
        );
    }
}

export default SidebarCardComponent;