import React from 'react';
import './App.css';
import { Container } from '@mui/material';
import RoutesComponent from './components/routes/Routes';
function App() {
  return (
    <div>
      <Container className='body-bg'>
        {/* <HeaderComponent />
        <HomeComponent/>
        <FooterComponent /> */}
        <RoutesComponent />
      </Container>
    </div>
  );
}

export default App;
