import * as React from 'react';
import "./../kundaliMatch-report/kundaliMatchReport.css";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import HorizantalTopBanner from '../appBanners/HorizantalTopBanner';
import SidebarCardComponent from '../sidebarCard/SidebarCardComponent';
import VerticalAppBanner from '../appBanners/VerticalAppBanner';
import AstrologerComponent from '../astrologer/AstrologerComponent';
import HoroscopeComponent from '../horoscope/HoroscopeComponent';
import Table from 'react-bootstrap/Table';
import manIcon from "./../../icons/male.svg";
import womenIcon from "./../../icons/female.svg";
import coupleImg from "./../../icons/couple.svg";
import astroconsult from "./../../images/astroconsult.svg";
import Alert from 'react-bootstrap/Alert';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';



interface KundaliMatchReportProps {

}

interface KundaliMatchReportState {
    title: String
    kundaliMatchData: Array<KundaliMatchDetailsState>;
    basicTable: Array<BasicKundaliTable>;
    avakhadaTable: Array<AvakhadaTableState>;
    astroconsultConclusion: Array<KundaliDescState>;
    doshTitle: String;
    doshValue: any;
    manglikTitle: String;
    manglikValue: any;

}
interface BasicKundaliTable {
    name: String;
    details: String;
}
interface KundaliMatchDetailsState {
    id: any;
    title: String;
    kundaliMatchP: Array<KundaliDescState>;
}
interface KundaliDescState {
    descText: String;
}
interface AvakhadaTableState {
    attribute: String;
    male: String;
    female: String;
    point: String;
    areaOfLife: String;
    description: String;
}


class KundaliMatchReport extends React.Component<KundaliMatchReportProps, KundaliMatchReportState> {
    constructor(props: KundaliMatchReportProps) {
        super(props);
        this.state = {
            title: "Kundli Matching Report",
            basicTable: [
                { name: "Name", details: "Rahul" },
                { name: "Birth Date & Time", details: "1 January 1990 | 1:1" },
                { name: "Birth Place", details: "New Delhi, Delhi, India" },
                { name: "Janam Rashi", details: "Aquarius" },
                { name: "Manglik", details: "yes" },
            ],
            avakhadaTable: [
                { attribute: "Varna", male: "Shudra", female: "Kshatriya", point: "1/1", areaOfLife: "Work", description: "The work gunas don't match for this pair and the overall compatibility may not be at its best" },
                { attribute: "Vashya", male: "Chatushpada", female: "Chatu", point: "1/2", areaOfLife: "Dominance", description: "They will have equal love and regard for each other which will grow more with time. Overall, this is an excellent match of Vasyaâ€™s." },
                { attribute: "Tara", male: "Mitra", female: "Pratyari", point: "3/3", areaOfLife: "Destiny", description: "Both of them will be encouraging and respectful towards each other. Overall, this is an excellent alliance." }
            ],
            doshTitle: "Ashtakoot",
            doshValue: 14,
            manglikTitle: "Manglik Match",
            manglikValue: "No",
            kundaliMatchData: [
                {
                    id: 1, title: "Varna",
                    kundaliMatchP: [
                        { descText: "The boy’s varan is Vaishya while the girl comes under Kshatriya varan.This type of combination is not very much favorable for a union. At times, the natives may have some issues in adjusting with each other and therefore they may be less jovial towards one another. The natives go through different moods and emotional changes simultaneously. The two individuals are apt to have disparate attitudes and values in home and domestic affairs. The energy flow is from negative to positive terminal, which is wrong. Native’s ego can cause serious difficulties within the relationship. However, if other gunas matches well, then this alliance may be taken into consideration." },
                    ],
                },
                {
                    id: 2, title: "Vasya",
                    kundaliMatchP: [
                        { descText: "The boy and the girl both come under Chatushpad vashya. This pair indicates capability of achieving happiness, health and status together. There is good aspect of decent married life as both have a fair demand on each other on certain occasions. They encourage each other and mutual responsibility makes them loyal and attractive for each other. This combination comes out effectively in work and study and in matters related to diet, dress and personal hygiene. Both will have an emotional and a very soft-core for each other in their hearts. I would advise you to go in for ceremonial marriage." },
                    ],

                },
                {
                    id: 3, title: "Tara",
                    kundaliMatchP: [
                        { descText: "Tara for boy is Mitra and for girl is Pratyari tara. As far as tara compatibility shows it is a very compatible match. The boy’s behavior will be very much accommodating and he will turnout firm in the midst of adversities. The boy can help the girl to be more courageous and energetic in implementing plans, goals and in over coming fear and negativity. The boy will also help the girl to fill in some of the practical details in his broad philosophical outlines. A compatible match, but still overall compatibility has to be there." },

                    ],

                },
                {
                    id: 4, title: "Yoni",
                    kundaliMatchP: [
                        { descText: "Yoni for boy is sarp while for girl is Gaj. A warm and gratifying union, as each side has a strong desire to please the other. They will be effective in taking practical action to help those less fortunate than themselves. The girl can help to channel the energy of the boy into more constructive actions. The boy will help the other to put educational, philosophical or religious goals into action. The natives will enjoy each other’s company in social activities, romantic relationships, games, sports and travels. Physically, both will get on very well." },

                    ],

                },
                {
                    id: 5, title: "Rasi Lord",
                    kundaliMatchP: [
                        { descText: "The boy belongs to the rasi lord Venus and the girl belongs to Mars lordship. This combination indicates a long married life. Involved parties in this relationship are best friends as well as lovers. These people have similar likes and dislikes, thoughts and feelings, behaviors and hobbies. They genuinely enjoy each other’s company and do not hesitate to tell their partner how strongly they feel. Even with careers, children, and all the other accoutrements of a busy life, these successful couples make time for each other a priority. Overall, a good coalition." },

                    ],

                },
                {
                    id: 6, title: "Gana",
                    kundaliMatchP: [
                        { descText: "The boy’s gan is Dev and the girl comes under Manushya gan. This is considered to be an excellent combination for gana match. They will go well with each other and will be very supportive. The boy will be generally peace-loving, contented and calm. The girl will do her best to help boy achieving his career and social objectives. On the other hand boy will also utilze the inherent potential of the girl and supports her in all her endeavors. They will complement each other by strengthing the each other’s weak areas and motivating them to accomplish their shared goals." },

                    ],

                },
                {
                    id: 7, title: "Bhakoot",
                    kundaliMatchP: [
                        { descText: "Vrishabh Zodiac sign is for boy and the girl's Zodiac sign is Mesha. This can not be considered as a good combination as far as sign compatibility is concerned. Their egos will be the biggest problem in this union. The girl will be arrogant and over-assertive which will not go well with the boy. Boy will also try to be dominant which will lead to disharmony and fights. In order to sustain the relationship, girl will have to tone down her aggression and boy has to listen to her carefully. Instead of treating each other as competitors, they should try to complement each other." },

                    ],

                },
                {
                    id: 8, title: "Nadi",
                    kundaliMatchP: [
                        { descText: "The boy and the girl both come under Madhya nadi. This is not a preferred alliance according to nadi compatibility. If the wedding takes place, there is the danger of continued illness to one or both of the natives. It will be very difficult for them to adjust according to the living style, moods and feelings of the other. Sometimes they may snese that their thinking is not only different, but polar opposite with each other. Lack of trust in other might make their relationship difficult too." },

                    ],

                },

            ],
            astroconsultConclusion: [
                { descText: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s." },
                { descText: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s." },
            ]
        };
    }
    render() {

        return (<React.Fragment>
            <main>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                        <Grid item md={9} xs={12}>
                            <HorizantalTopBanner />
                            <Box sx={{ flexGrow: 1 }} className='kundali-match-report'>
                                <Grid container spacing={2}>
                                    <Grid item md={12} style={{ width: "100%" }}>
                                        <Typography variant="h4" className='heading-title'>{this.state.title}</Typography>
                                        <div className='kundali-matcher'>
                                            <Typography variant="h5" className='badge bg-danger'>Match Name </Typography>
                                            <span><img src={coupleImg} alt="couple" width={54} height={54} /></span>
                                            <Typography variant="h5" className='badge bg-danger'>Match Partner Name</Typography>
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item md={6} xs={12}>
                                        <div className='kundali-match-table'>
                                            <Table responsive>
                                                <thead>
                                                    <tr>
                                                        <th colSpan={2}><img src={manIcon} alt="man" width={12} height={25} />  Basic Details </th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {
                                                        this.state.basicTable.map((basicTable, index) => {
                                                            return <tr key={index}>
                                                                <td>{basicTable.name}</td>
                                                                <td style={{ color: basicTable.details === "Yes" ? "green" : "" || basicTable.details === "yes" ? "green" : "" || basicTable.details === "No" ? "red" : "" || basicTable.details === "no" ? "red" : "" }}>{basicTable.details}</td>
                                                            </tr>
                                                        })
                                                    }

                                                </tbody>
                                            </Table>
                                        </div>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <div className='kundali-match-table'>
                                            <Table responsive>
                                                <thead>
                                                    <tr>
                                                        <th colSpan={2}><img src={womenIcon} alt="man" width={12} height={24} />  Basic Details </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.basicTable.map((basicTable, index) => {
                                                            return <tr key={index}>
                                                                <td>{basicTable.name}</td>
                                                                <td style={{ color: basicTable.details === "Yes" ? "green" : "" || basicTable.details === "yes" ? "green" : "" || basicTable.details === "No" ? "red" : "" || basicTable.details === "no" ? "red" : "" }}>{basicTable.details}</td>
                                                            </tr>
                                                        })
                                                    }

                                                </tbody>
                                            </Table>
                                        </div>
                                    </Grid>
                                </Grid>
                                <div className='kundali-avakhada-table'>
                                    <h6 className='title'>Avakhada Details</h6>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Table striped hover responsive>
                                                <thead>
                                                    <tr>
                                                        <th>Attribute</th>
                                                        <th>Male</th>
                                                        <th>Female</th>
                                                        <th>Points</th>
                                                        <th>Area Of Life</th>
                                                        <th>Description</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.avakhadaTable.map((avakhandaItems, index) => {
                                                            return <tr key={index}>
                                                                <td>{avakhandaItems.attribute}</td>
                                                                <td>{avakhandaItems.male}</td>
                                                                <td>{avakhandaItems.female}</td>
                                                                <td>{avakhandaItems.point}</td>
                                                                <td>{avakhandaItems.areaOfLife}</td>
                                                                <td>{avakhandaItems.description}</td>
                                                            </tr>
                                                        })
                                                    }


                                                </tbody>
                                            </Table>
                                        </Grid>
                                    </Grid>
                                </div>
                                <Grid container spacing={2}>
                                    <Grid item md={12}>
                                        <h6 className='dosha-title'>Dosha</h6>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <div className='kundali-ashtakoot'>
                                            {['danger'].map((variant) => (
                                                <Alert key={variant} variant={variant}>
                                                    <div className='asashtakoot-box'>
                                                        <div style={{ width: 100, height: 100, fontWeight: 700, lineHeight: "27px" }}>
                                                            <CircularProgressbar value={this.state.doshValue} maxValue={36} text={`${this.state.doshValue}/36`} strokeWidth={10}
                                                                styles={buildStyles({
                                                                    strokeLinecap: 'rounded',
                                                                    textSize: '22px',
                                                                    pathColor: `#FF0000`,
                                                                    textColor: '#FF0000',
                                                                    trailColor: '#fff',
                                                                })} />
                                                        </div>
                                                        <h5>{this.state.doshTitle}</h5>
                                                    </div>

                                                </Alert>
                                            ))}
                                        </div>
                                    </Grid>
                                    <Grid item md={6} xs={12}>

                                        {
                                            this.state.manglikValue === "No" ? <div className='kundali-ashtakoot'>
                                                {['success'].map((variant) => (
                                                    <Alert key={variant} variant={variant}>
                                                        <div className='asashtakoot-box'>
                                                            <div style={{ width: 100, height: 100, fontWeight: 700, lineHeight: "27px" }}>
                                                                <CircularProgressbar value={this.state.manglikValue} maxValue={100} text={`${this.state.manglikValue}`} strokeWidth={10}
                                                                    styles={buildStyles({
                                                                        strokeLinecap: 'rounded',
                                                                        textSize: '22px',
                                                                        pathColor: `#21BD60`,
                                                                        textColor: '#21BD60',
                                                                        trailColor: '#fff',
                                                                    })} />
                                                            </div>
                                                            <h5>Manglik Match</h5>
                                                        </div>
                                                    </Alert>
                                                ))}
                                            </div> : <div className='kundali-ashtakoot'>
                                                {['danger'].map((variant) => (
                                                    <Alert key={variant} variant={variant}>
                                                        <div className='asashtakoot-box'>
                                                            <div style={{ width: 100, height: 100, fontWeight: 700, lineHeight: "27px" }}>
                                                                <CircularProgressbar value={this.state.manglikValue} maxValue={100} text={`Yes`} strokeWidth={10}
                                                                    styles={buildStyles({
                                                                        strokeLinecap: 'rounded',
                                                                        textSize: '22px',
                                                                        pathColor: `#FF0000`,
                                                                        textColor: '#FF0000',
                                                                        trailColor: '#fff',
                                                                    })} />
                                                            </div>
                                                            <h5>Manglik Match</h5>
                                                        </div>
                                                    </Alert>
                                                ))}
                                            </div>


                                        }

                                    </Grid>
                                </Grid>
                            </Box>


                        </Grid>
                        <Grid item md={3} xs={12}>
                            <SidebarCardComponent />
                            <VerticalAppBanner />
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ flexGrow: 1 }} className='mt-16 astroconsult-section'>
                    <Grid container spacing={6}>
                        <Grid item md={8}>
                            <Typography variant='h4'>Astroconsult Conclusion</Typography>
                            {
                                this.state.astroconsultConclusion.map((astroConclusion, index) => {
                                    return <Typography key={index}>
                                        {astroConclusion.descText}
                                    </Typography>
                                })
                            }
                        </Grid>
                        <Grid item md={4}>
                            <div id='egg'><img src={astroconsult} height={213} width={239} alt="" /></div>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ flexGrow: 1 }} className='horoscope-details-about mt-16'>
                    <Grid container spacing={2} className='mb-4'>
                        <Grid item md={12}>
                            <Typography variant="h3" className='heading-title'>Report Interpretation</Typography>

                            {
                                this.state.kundaliMatchData.map((aboutData) => {
                                    return <div className='details-about-wrap' key={aboutData.id}>
                                        <Typography variant="h6" className='sub-heading'>{aboutData.title}</Typography>
                                        {
                                            aboutData.kundaliMatchP.map((descP, index) => {
                                                return <Typography variant='body1' key={index}>
                                                    {descP.descText}
                                                </Typography>
                                            })
                                        }
                                    </div>
                                })
                            }
                        </Grid>
                    </Grid>
                </Box>
                <AstrologerComponent />
                <HoroscopeComponent />
            </main>
        </React.Fragment>);
    }
}

export default KundaliMatchReport;

