import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import appBanner from "../../images/app-banner-1108x261.png";
import { Link } from '@mui/material';




interface HorizantalTopBannerProps {

}

interface HorizantalTopBannerState {

}

class HorizantalTopBanner extends React.Component<HorizantalTopBannerProps, HorizantalTopBannerState> {
    constructor(props: HorizantalTopBannerProps) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <React.Fragment>
                <Box sx={{ width: '100%' }}>
                    <Grid container spacing={2} className='app-banner-box'>
                        <Grid item xs={12}>
                            <Link href="/" className='app-banner'>
                                <img src={appBanner} width={1108} height={261} alt='app' />
                            </Link>
                        </Grid>
                    </Grid>
                </Box>

            </React.Fragment>
        );
    }
}

export default HorizantalTopBanner;