import * as React from 'react';
import "../blogs/blog.css";
import Slider from "react-slick";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Link } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import blogImg1 from "../../images/blog-1.png";
import blogImg2 from "../../images/blog-2.png";
import blogImg3 from "../../images/blog-3.png";
import blogImg4 from "../../images/blog-4.png";
import VisibilityIcon from '@mui/icons-material/Visibility';

import { NextArrow, PrevArrow } from "./../slick-arrow/SlickCarousalArrow";

interface BlogComponentProps {

}

interface BlogComponentState {

}

class BlogComponent extends React.Component<BlogComponentProps, BlogComponentState> {
    constructor(props: BlogComponentProps) {
        super(props);
        this.state = {};
    }
    render() {
        const Carouselsettings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 4,
            initialSlide: 0,
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />,
            variableWidth: true,
            swipeToSlide: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <React.Fragment>
                <Box sx={{ flexGrow: 1 }} className='blog-section mt-16'>
                    <Typography variant='h4' className='heading-wtbg'>Latest Blog</Typography>
                    <Grid container spacing={2}>
                        <Grid item md={12} style={{ overflow: "hidden" }}>
                            <Slider {...Carouselsettings}>
                                <Card className='blog-card'>
                                    <CardContent className='blog-card-content'>
                                        <Link href="/">
                                            <img src={blogImg1} alt="blog" width={270} height={181} style={{ maxWidth: "100%", height: "auto" }} />
                                            <div className='blog-cardbody'>
                                                <h6 className='blog-card-title'>Lorem Ipsum is simply dummy text of the printing and typesetting industry printing. </h6>
                                                <div className='date-author-box'>
                                                    <span>Admin</span>
                                                    <span>Jul 13, 2023</span>
                                                </div>
                                            </div>
                                            <div className='card-overlay'>
                                                <VisibilityIcon fontSize='small' /> 223
                                            </div>
                                        </Link>
                                    </CardContent>
                                </Card>
                                <Card className='blog-card' style={{ maxWidth: 270 }}>
                                    <CardContent className='blog-card-content'>
                                        <Link href="/">
                                            <img src={blogImg2} alt="blog" width={270} height={181} style={{ maxWidth: "100%", height: "auto" }} />
                                            <div className='blog-cardbody'>
                                                <h6 className='blog-card-title'>Lorem Ipsum is simply dummy text of the printing and typesetting industry printing. </h6>
                                                <div className='date-author-box'>
                                                    <span>Admin</span>
                                                    <span>Jul 13, 2023</span>
                                                </div>
                                            </div>
                                            <div className='card-overlay'>
                                                <VisibilityIcon fontSize='small' /> 223
                                            </div>
                                        </Link>
                                    </CardContent>
                                </Card>
                                <Card className='blog-card' style={{ maxWidth: 270 }}>
                                    <CardContent className='blog-card-content'>
                                        <Link href="/">
                                            <img src={blogImg3} alt="blog" width={270} height={181} style={{ maxWidth: "100%", height: "auto" }} />
                                            <div className='blog-cardbody'>
                                                <h6 className='blog-card-title'>Lorem Ipsum is simply dummy text of the printing and typesetting industry printing. </h6>
                                                <div className='date-author-box'>
                                                    <span>Admin</span>
                                                    <span>Jul 13, 2023</span>
                                                </div>
                                            </div>
                                            <div className='card-overlay'>
                                                <VisibilityIcon fontSize='small' /> 223
                                            </div>
                                        </Link>
                                    </CardContent>
                                </Card>
                                <Card className='blog-card' style={{ maxWidth: 270 }}>
                                    <CardContent className='blog-card-content'>
                                        <Link href="/">
                                            <img src={blogImg4} alt="blog" width={270} height={181} style={{ maxWidth: "100%", height: "auto" }} />
                                            <div className='blog-cardbody'>
                                                <h6 className='blog-card-title'>Lorem Ipsum is simply dummy text of the printing and typesetting industry printing. </h6>
                                                <div className='date-author-box'>
                                                    <span>Admin</span>
                                                    <span>Jul 13, 2023</span>
                                                </div>
                                            </div>
                                            <div className='card-overlay'>
                                                <VisibilityIcon fontSize='small' /> 223
                                            </div>
                                        </Link>
                                    </CardContent>
                                </Card>
                                <Card className='blog-card' style={{ maxWidth: 270 }}>
                                    <CardContent className='blog-card-content'>
                                        <Link href="/">
                                            <img src={blogImg1} alt="blog" width={270} height={181} style={{ maxWidth: "100%", height: "auto" }} />
                                            <div className='blog-cardbody'>
                                                <h6 className='blog-card-title'>Lorem Ipsum is simply dummy text of the printing and typesetting industry printing. </h6>
                                                <div className='date-author-box'>
                                                    <span>Admin</span>
                                                    <span>Jul 13, 2023</span>
                                                </div>
                                            </div>
                                            <div className='card-overlay'>
                                                <VisibilityIcon fontSize='small' /> 223
                                            </div>
                                        </Link>
                                    </CardContent>
                                </Card>
                                <Card className='blog-card' style={{ maxWidth: 270 }}>
                                    <CardContent className='blog-card-content'>
                                        <Link href="/">
                                            <img src={blogImg2} alt="blog" width={270} height={181} style={{ maxWidth: "100%", height: "auto" }} />
                                            <div className='blog-cardbody'>
                                                <h6 className='blog-card-title'>Lorem Ipsum is simply dummy text of the printing and typesetting industry printing. </h6>
                                                <div className='date-author-box'>
                                                    <span>Admin</span>
                                                    <span>Jul 13, 2023</span>
                                                </div>
                                            </div>
                                            <div className='card-overlay'>
                                                <VisibilityIcon fontSize='small' /> 223
                                            </div>
                                        </Link>
                                    </CardContent>
                                </Card>
                            </Slider>
                        </Grid>
                    </Grid>
                </Box>
            </React.Fragment>
        );
    }
}

export default BlogComponent;


