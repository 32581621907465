import * as React from 'react';
import "./../static-pages/staticPages.css";
import HorizantalTopBanner from '../appBanners/HorizantalTopBanner';
import SidebarCardComponent from '../sidebarCard/SidebarCardComponent';
import VerticalAppBanner from '../appBanners/VerticalAppBanner';
import AstrologerComponent from '../astrologer/AstrologerComponent';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Button, Link, Typography, Breadcrumbs } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import aboutUsHeroImg from "./../../images/about-us.svg";


interface AboutUsProps {

}

interface AboutUsState {

}

class AboutUs extends React.Component<AboutUsProps, AboutUsState> {
    constructor(props: AboutUsProps) {
        super(props);
        this.state = {

        };
    }
    render() {
        return (
            <React.Fragment>
                <main>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item md={9} xs={12}>
                                <HorizantalTopBanner />
                                <Box sx={{ width: '100%' }} className='heading custom-breadcrumb mt-16'>
                                    <Breadcrumbs separator={<NavigateNextIcon />} aria-label="breadcrumb">
                                        <Link href="/home">Home</Link>
                                        <Typography>About  Us</Typography>
                                    </Breadcrumbs>
                                </Box>
                                <Box sx={{ width: '100%' }} className="static-page-bg">
                                    <Grid container spacing={2}>
                                        <Grid item md={12} xs={12}>
                                            <div className='static-page-box'>
                                                <Typography variant='h2'>Who we are</Typography>
                                                <Typography variant='body1'>
                                                    AstroConsult is the best astrology website for online Astrology predictions. With a gigantic pool of 10000+ Vedic astrologers, Tarot readers, Numerologists, Vastu experts and more, AstroConsult lets you connect with these experts over call and chat and get answers to all your problems
                                                </Typography>
                                                <Typography variant='body1'>
                                                    From Kundli matching to Kundli predictions, Marriage Compatibility on AstroConsult, the visitor is to get the best future predictions across aspects like Marriage, Love, Career or Health and more. When using AstroConsult, you can trust us for highly accurate, precise and well researched astrological content that would offset most of your worries.
                                                </Typography>
                                                <div className='about-us-heroImg'>
                                                    <img src={aboutUsHeroImg} alt='' width={1080} height={607} />
                                                    <div className='overlay'>
                                                        <ul>
                                                            <li>
                                                                <h3>2.4L+</h3>
                                                                <span>Happy Customers</span>
                                                            </li>
                                                            <li>
                                                                <h3>40+</h3>
                                                                <span>Countries</span>
                                                            </li>
                                                            <li>
                                                                <h3>10000+</h3>
                                                                <span>Astrologers</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <Typography variant='h2'>What we do</Typography>
                                                <Typography variant='body1'>
                                                    AstroConsult brings together astrologers and their boundless knowledge about the occult science of Astrology on one platform and lets you connect with them 24*7. Apart from the best future predictions that help you get through the difficult phase of life, AstroConsult also offers Free Live astrology sessions, Daily horoscope, Free kundli matching service, Astro mall and much more.
                                                </Typography>
                                                <Typography variant='h2'>Why AstroConsult</Typography>
                                                <Typography variant='body1'>
                                                    AstroConsult, today, enjoys a tremendous audience across 40+ countries with an ever-expanding pool of 10000+ top Vedic astrologers, Tarot readers, Numerologists, Vastu experts from around the world. As we understand how one seeks only accurate predictions for themselves, thus we ensure every astrologer you connect with on our platform is the best of the best. For the same purpose, we let users rate the astrologers; and recommend to you only the ones who have a 4+ rating. This way, we assure you of premium quality predictions. This is one of the reasons why we are able to garner a huge base of over 2.4L customers and are currently doing over 1-Lakh minutes of daily consultation. Also, another core goal for us is to respect your privacy so you can trust us and certainly buzz about us. Wink!! Wink!!
                                                </Typography>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Box>

                            </Grid>
                            <Grid item md={3} xs={12}>
                                <SidebarCardComponent />
                                <VerticalAppBanner />
                            </Grid>
                        </Grid>
                    </Box>
                    <AstrologerComponent />



                </main>
            </React.Fragment>
        );
    }
}

export default AboutUs;