import * as React from 'react';
import "./../freeKundaliDetails/kundaliDetails.css";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Link, Typography, Breadcrumbs } from '@mui/material';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import HorizantalTopBanner from '../appBanners/HorizantalTopBanner';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SidebarCardComponent from '../sidebarCard/SidebarCardComponent';
import VerticalAppBanner from '../appBanners/VerticalAppBanner';
import AstrologerComponent from '../astrologer/AstrologerComponent';
import HoroscopeComponent from '../horoscope/HoroscopeComponent';
import Table from 'react-bootstrap/Table';

interface KundaliDetailsComponentProps {


}

interface KundaliDetailsComponentState {
    tabs: Array<FreeKundaliTab>;


}

interface FreeKundaliTab {
    title: String;
    key: any;
    tableBasic: Array<FreeKundaliTable>;
    tableAvakhada: Array<FreeKundaliTable>;

}

interface FreeKundaliTable {
    tableHeading: string;
    tableItem: Array<TableRowState>;
}
interface TableRowState {
    name: String;
    details: String;
}

class KundaliDetailsComponent extends React.Component<KundaliDetailsComponentProps, KundaliDetailsComponentState> {
    constructor(props: KundaliDetailsComponentProps) {
        super(props);
        this.state = {
            tabs: [
                {
                    key: "basic", title: "Basic",

                    tableBasic: [
                        {
                            tableHeading: "Basic",
                            tableItem: [
                                { name: "Name", details: "Basic" },
                                { name: "Date", details: "01/01/1990" },
                                { name: "Place", details: "Surat, Gujarat, India" },
                                { name: "Latitude", details: "21.2" },
                                { name: "Latitude", details: "72.03" },
                                { name: "Timezone", details: "GMT+5.5" },
                                { name: "Sunrise", details: "7:16:22" },
                                { name: "Sunset", details: "18:8:13" },
                                { name: "Ayanamsha", details: "23.71738" },
                            ]
                        },
                        {
                            tableHeading: " Panchang Details",
                            tableItem: [
                                { name: "Tithi", details: "ShuklaPanchami" },
                                { name: "Karan", details: "Bav" },
                                { name: "Yog", details: "Siddhi" },
                                { name: "Nakshatra", details: "Shatabhisha" },
                            ]
                        }
                    ],
                    tableAvakhada: [
                        {
                            tableHeading: "Avakhada Details",
                            tableItem: [
                                { name: "Varna", details: "Vaishya" },
                                { name: "Vashya", details: "Nara" },
                                { name: "Yoni", details: "Ashva" },
                                { name: "Gan", details: "Rakshasa" },
                                { name: "Nadi", details: "Adhya" },
                                { name: "Sign", details: "Aquarius" },
                                { name: "Sign Lord", details: "Saturn" },
                                { name: "Nakshatra-Charan", details: "Shatabhisha" },
                                { name: "Yog", details: "Siddhi" },
                                { name: "Karan", details: "Bav" },
                                { name: "Tithi", details: "ShuklaPanchami" },
                                { name: "Yunja", details: "Antya" },
                                { name: "Tatva", details: "Air" },
                                { name: "Name alphabet", details: "Go" },
                                { name: "Paya", details: "Copper" },

                            ]
                        },
                    ]
                },
                {
                    key: "charts", title: "Charts",
                    tableBasic: [
                        {
                            tableHeading: "",
                            tableItem: [
                                { name: "", details: "" }
                            ]
                        },

                    ],
                    tableAvakhada: [
                        {
                            tableHeading: "",
                            tableItem: [
                                { name: "", details: "" },


                            ]
                        },
                    ]
                },
                {
                    key: "kp", title: "KP",
                    tableBasic: [
                        {
                            tableHeading: "",
                            tableItem: [
                                { name: "", details: "" }
                            ]
                        },

                    ],
                    tableAvakhada: [
                        {
                            tableHeading: "",
                            tableItem: [
                                { name: "", details: "" },


                            ]
                        },
                    ]
                },
                {
                    key: "ashtakvarga", title: "Ashtakvarga",
                    tableBasic: [
                        {
                            tableHeading: "",
                            tableItem: [
                                { name: "", details: "" }
                            ]
                        },

                    ],
                    tableAvakhada: [
                        {
                            tableHeading: "",
                            tableItem: [
                                { name: "", details: "" },


                            ]
                        },
                    ]
                },
                {
                    key: "dasha", title: "Dasha",
                    tableBasic: [
                        {
                            tableHeading: "",
                            tableItem: [
                                { name: "", details: "" }
                            ]
                        },

                    ],
                    tableAvakhada: [
                        {
                            tableHeading: "",
                            tableItem: [
                                { name: "", details: "" },


                            ]
                        },
                    ]
                },
                {
                    key: "report", title: "Report",
                    tableBasic: [
                        {
                            tableHeading: "",
                            tableItem: [
                                { name: "", details: "" }
                            ]
                        },

                    ],
                    tableAvakhada: [
                        {
                            tableHeading: "",
                            tableItem: [
                                { name: "", details: "" },


                            ]
                        },
                    ]
                },
            ],


        };
    }
    render() {
        return (<React.Fragment>
            <main>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                        <Grid item md={9} xs={12}>
                            <HorizantalTopBanner />
                            <Box sx={{ width: '100%' }} className='heading custom-breadcrumb mt-16'>
                                <Breadcrumbs separator={<NavigateNextIcon />} aria-label="breadcrumb">
                                    <Link href="/free-kundali">Free Kundali</Link>
                                    <Typography>Kundali Details </Typography>
                                </Breadcrumbs>
                            </Box>
                            <Box sx={{ width: '100%' }} className="horoscope-tab">
                                <Tabs defaultActiveKey="basic" id="freeKundali" className="mb-0 kundali-tab" fill>

                                    {
                                        this.state.tabs.map((tabs) => {
                                            return <Tab key={tabs.key} eventKey={tabs.key} title={tabs.title}>
                                                <div className='free-kundali-contents'>
                                                    <Grid container spacing={2}>
                                                        <Grid item md={6} xs={12}>
                                                            {
                                                                tabs.tableBasic.map((table, index) => {
                                                                    return <Table striped hover responsive>
                                                                        <thead key={index}>
                                                                            <tr>
                                                                                <th colSpan={4}>{table.tableHeading}</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                table.tableItem.map((tableItem, index) => {
                                                                                    return <tr key={index}>
                                                                                        <td>{tableItem.name}</td>
                                                                                        <td>{tableItem.details}</td>
                                                                                    </tr>
                                                                                })
                                                                            }
                                                                        </tbody>
                                                                    </Table>
                                                                })
                                                            }
                                                        </Grid>
                                                        <Grid item md={6} xs={12}>
                                                            {
                                                                tabs.tableAvakhada.map((table, index) => {
                                                                    return <Table striped hover responsive>
                                                                        <thead key={index}>
                                                                            <tr>
                                                                                <th colSpan={4}>{table.tableHeading}</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                table.tableItem.map((tableItem, index) => {
                                                                                    return <tr key={index}>
                                                                                        <td>{tableItem.name}</td>
                                                                                        <td>{tableItem.details}</td>
                                                                                    </tr>
                                                                                })
                                                                            }
                                                                        </tbody>
                                                                    </Table>
                                                                })
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </Tab>
                                        })
                                    }

                                </Tabs>
                            </Box>

                        </Grid>
                        <Grid item md={3} xs={12}>
                            <SidebarCardComponent />
                            <VerticalAppBanner />
                        </Grid>
                    </Grid>
                </Box>
                <AstrologerComponent />
                <HoroscopeComponent />


            </main>
        </React.Fragment>);
    }
}

export default KundaliDetailsComponent;