import * as React from 'react';
import "./../festival-list/festivalLists.css";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Link, Typography, Breadcrumbs } from '@mui/material';
import HorizantalTopBanner from '../appBanners/HorizantalTopBanner';
import SidebarCardComponent from '../sidebarCard/SidebarCardComponent';
import VerticalAppBanner from '../appBanners/VerticalAppBanner';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import festivalThumbImg from "./../../images/deepak.png";
import Table from 'react-bootstrap/Table';


interface FestivalListsProps {

}

interface FestivalListsState {
    title: String;
    subTitle: String;
    festivalAboutUs: Array<FestivalAboutUs>;
    festivalThumb: any;
    festivalThumbTitle: any;
    festivalTable: Array<FestivalTable>;
    festivalCalendar: Array<FestivalCalendar>;
}
interface FestivalAboutUs {
    desc: String;
}

interface FestivalTable {
    title: String;
    tableData: Array<FestivalTableData>;

}
interface FestivalTableData {
    name: String;
    data: String;
    dataLink: any;
}
interface FestivalCalendar {
    heading: String;
    textDesc: String;
    actionTitle: String;
    actionLink: any;
}





class FestivalLists extends React.Component<FestivalListsProps, FestivalListsState> {
    constructor(props: FestivalListsProps) {
        super(props);
        this.state = {
            title: "Festival Calendar 2024",
            subTitle: "Indian Festivals & Holidays",
            festivalAboutUs: [
                { desc: "Hindu festivals are observed on the basis of movements and position of Moon (Lunar Month) & Sun (Solar Month) in the celestial belt. The date, time and auspicious muhurat for Hindu festivals can differ for two cities on the basis of location due to a difference in time zone." },
                { desc: "They are widely known as Tyohar or Parva, i.e. when the day is celebrated and Hindu Vrat, i.e when one fasts during the day. On most of the festivals as per Hindu Calendar, fasting is significantly included, where one fasts for a whole day or by the time Moon appears in the sky. Since the ancient times, Hindu Calendar is used to measure time and day and marks the incoming of important days, muhurats and festivals." }
            ],
            festivalThumb: festivalThumbImg,
            festivalThumbTitle: "festival",
            festivalTable: [
                {
                    title: "January Festivals 2024",
                    tableData: [
                        { name: "Monday, January 10", data: "Sankat Chauth", dataLink: "" },
                        { name: "Friday, January 14", data: "Lori", dataLink: "" },
                        { name: "Saturday, January 15", data: "Makar Sankranti, Pongal, Uttrayan", dataLink: "" },
                        { name: "Friday, January 21", data: "Mauni Amavasya", dataLink: "" },
                        { name: "Wednesday, January 26", data: "Vasant Panchami", dataLink: "" },
                        { name: "Wednesday, January 26", data: "Republic Day", dataLink: "" },
                    ]
                },
                {
                    title: "February Festivals 2024",
                    tableData: [
                        { name: "Monday, February 14", data: "Valentine’s Day", dataLink: "valentines-day" },
                        { name: "Friday, February 18", data: "Mahashivratri", dataLink: "mahashivratri" },
                    ]
                }
            ],
            festivalCalendar: [
                { heading: "Hindu Calendar 2024", textDesc: "Depending on the lunisolar format, this calendar holds importance in North India. Hindu calendar 2024 shows the Hindu festivals, Panchang, and tithi. In a particular year, there are 12 months of 29.5 days, which makes the year 354 days. This lack of days in the year leads to each festival moving back by 11 days. So, to amend the Hindu calendar, an extra month or a leap month is added once every three years— an accurate month and an approximate year. The majority of the Indian festivals, like Diwali, Holi, Navratri, etc., work depending on the Hindu calendar.", actionTitle: "Click here, For Hindu Events 2024", actionLink: "hindu-events-2024" },
                { heading: "Islamic Calendar 2024", textDesc: "Unlike the Hindu calendar, this one marks the month's beginning with the New Moon visibility for the first time. The Islamic calendar 2024 relies on the movement of the planet Moon. The Islamic calendar shows the festivals, fasts, and other events of the Islam religion. Alternately, the months in the Islamic calendar either have 29 days or 30 days, except for the twelfth one. Furthermore, there are no leap days or months present in the 2023 Islamic calendar. Therefore, the month's name doesn't lie in the same season but falls over the entire solar.", actionTitle: "Click here, For Islamic Festivals 2024", actionLink: "https://astrotalk.com/indian-calendar-festivals-2023/islamic-calendar-2023" }
            ]
        };
    }
    render() {
        return (
            <React.Fragment>
                <main>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item md={9} xs={12}>
                                <HorizantalTopBanner />
                                <Box sx={{ width: '100%' }} className='heading custom-breadcrumb mt-16'>
                                    <Breadcrumbs separator={<NavigateNextIcon />} aria-label="breadcrumb">
                                        <Link href="/Home">Home</Link>
                                        <Typography>Festivals</Typography>
                                    </Breadcrumbs>
                                </Box>
                                <Box sx={{ width: '100%' }} className="festival-box-wrap festival">
                                    <Grid container spacing={{ xs: 2, md: 5 }}>
                                        <Grid item md={8} sm={7} xs={12}>
                                            <h1 className='festival-heading'>{this.state.title}</h1>
                                            <h2 className='festival-subheading'>{this.state.subTitle}</h2>
                                            {
                                                this.state.festivalAboutUs.map((desc, index) => {
                                                    return <Typography variant='body1' key={index}>
                                                        {desc.desc}
                                                    </Typography>
                                                })
                                            }
                                        </Grid>
                                        <Grid item md={4} sm={5} xs={12}>
                                            <img src={this.state.festivalThumb} alt={this.state.festivalThumbTitle} />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item md={12} className='festival-table'>
                                            {
                                                this.state.festivalTable.map((festivalTable, index) => {
                                                    return <div key={index}>
                                                        <h3 className='table-heading'>{festivalTable.title}</h3>
                                                        <Table striped hover responsive>
                                                            <tbody>
                                                                {
                                                                    festivalTable.tableData.map((tableData, index) => {
                                                                        return <tr key={index}>
                                                                            <td>{tableData.name}</td>
                                                                            <td><a href={`festival-details/${tableData.dataLink}`}>{tableData.data}</a></td>
                                                                        </tr>
                                                                    })
                                                                }
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                })
                                            }
                                        </Grid>
                                    </Grid>
                                </Box>

                            </Grid>
                            <Grid item md={3} xs={12}>
                                <SidebarCardComponent />
                                <VerticalAppBanner />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box className="festival-white-box mt-16">
                        <Grid container spacing={2}>
                            <Grid item md={12}>
                                {
                                    this.state.festivalCalendar.map((calendars, index) => {
                                        return <div className='festival-calenders' key={index}>
                                            <Typography variant='h4'>{calendars.heading}</Typography>
                                            <Typography variant='body1'>
                                                {calendars.textDesc} <Link href={`${calendars.actionLink}`} target="_blank">{calendars.actionTitle}</Link>
                                            </Typography>
                                        </div>
                                    })
                                }
                            </Grid>
                        </Grid>
                    </Box>
                </main>
            </React.Fragment>
        );
    }
}

export default FestivalLists;