import * as React from 'react';
import "./../marriage-compatibility-details/marriageCompatibilityDetails.css";
import HorizantalTopBanner from '../appBanners/HorizantalTopBanner';
import SidebarCardComponent from '../sidebarCard/SidebarCardComponent';
import VerticalAppBanner from '../appBanners/VerticalAppBanner';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import Alert from 'react-bootstrap/Alert';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import AstrologerComponent from '../astrologer/AstrologerComponent';

interface MarriageCompatibilityDetailsProps {

}

interface MarriageCompatibilityDetailsState {

}

class MarriageCompatibilityDetails extends React.Component<MarriageCompatibilityDetailsProps, MarriageCompatibilityDetailsState> {
    constructor(props: MarriageCompatibilityDetailsProps) {
        super(props);
        this.state = {

        };
    }
    render() {
        return (
            <React.Fragment>
                <main>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item md={9} xs={12}>
                                <HorizantalTopBanner />
                                <Box sx={{ flexGrow: 1 }} className='astrology-section'>
                                    <Grid container spacing={{ xs: 2, lg: 7 }} className='mb-45'>
                                        <Grid item md={7}>
                                            <Typography variant="h4" className='heading-title'>

                                            </Typography>

                                        </Grid>
                                        <Grid item md={5}>
                                            <div className='d-flex align-items-center h-100'>

                                            </div>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box sx={{ width: '100%' }} className='mt-16 compatibility-section'>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <div className='compatibility'>
                                                <Typography variant='h5' className='title'>Love Compatibility</Typography>
                                                <div className='compatibility-box'>
                                                    <p>
                                                        Aries, being the first zodiac sign, is said to be very courageous, ambitious and one who is ready to take risks in life. And similarly, the Scorpio personality is passionate, patient and understanding. While the kinda similar traits make many think that Aries and Scorpio make a great couple, but in reality, these similarities become the source of clashes. While the Scorpio likes patience, the Aries' hurrying attitude is not something the Scorpio likes or would want to be a part of. However, if Aries and Scorpio can combine their energy towards a solo goal, the fusion is beneficial. Honesty is of utmost importance for the signs with the Scorpio needing constant attention. Scorpio also doesn’t like anyone playing with their pride thus if you two seek to co-exist, it is best to not hurt the Scorpio ego and take things easy.
                                                    </p>
                                                    <div className='circle-box'>
                                                        <CircularProgressbar value={80} maxValue={100} text={`80%`} strokeWidth={10} counterClockwise={true}
                                                            styles={buildStyles({
                                                                strokeLinecap: 'rounded',
                                                                textSize: '22px',
                                                                pathColor: `#FF0000`,
                                                                textColor: '#FF0000',
                                                                trailColor: '#fff',
                                                            })} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='compatibility'>
                                                <Typography variant='h5' className='title'>Sexual Compatibility</Typography>
                                                <div className='compatibility-box'>
                                                    <p>
                                                        Scorpio is the most sensual sign of the zodiac jungle and Aries, on the other hand, too is very sensual. Aries and Scorpio compatibility is built on emotional connection. However, despite the same sensuality, the mood killer is the fact that the Aries doesn't shy away from getting into flings, which is something the Scorpio doesn't like. Scorpio seeks a forever kind of love and is comfortable with one who can assure them that they are here to stay. If Aries and Scorpio find an understanding inside their sexual relationship, they would probably need multiple beds. Yet, it is very difficult for them to find their shared language.
                                                    </p>
                                                    <div className='circle-box'>
                                                        <CircularProgressbar value={73} maxValue={100} text={`73%`} strokeWidth={10} counterClockwise={true}
                                                            styles={buildStyles({
                                                                strokeLinecap: 'rounded',
                                                                textSize: '22px',
                                                                pathColor: `#21BD60`,
                                                                textColor: '#21BD60',
                                                                trailColor: '#fff',
                                                            })} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='compatibility'>
                                                <Typography variant='h5' className='title'>Friendship Compatibility</Typography>
                                                <div className='compatibility-box'>
                                                    <p>
                                                        Both Scorpio and Aries are ruled by the planet Mars in astrology. The energies of two Mars natives could lead them to either become allies or ruthless enemies. Arguments in friendship are inevitable but the differences won't exist for long. Aries, as they like to be the starters of things, claims the spotlight in the Aries and Scorpio relationship, meanwhile, the Scorpio stays behind the scenes to support the Aries intellectually and emotionally. Aries and Scorpio could have a wonderful friendship and achieve great success while having their share of fun.
                                                    </p>
                                                    <div className='circle-box'>
                                                        <CircularProgressbar value={69} maxValue={100} text={`69%`} strokeWidth={10} counterClockwise={true}
                                                            styles={buildStyles({
                                                                strokeLinecap: 'rounded',
                                                                textSize: '22px',
                                                                pathColor: `#A586FF`,
                                                                textColor: '#A586FF',
                                                                trailColor: '#fff',
                                                            })} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='compatibility'>
                                                <Typography variant='h5' className='title'>Communication Compatibility</Typography>
                                                <div className='compatibility-box'>
                                                    <p>
                                                        Scorpios are very witty and open-minded people, and so are their conversations. And all they wish for is someone who would listen to them. Well, Aries won't. In a nutshell, Aries thinks of Scorpio as someone who is too dark and difficult to comprehend, and Scorpio finds Aries as someone who is too shallow for their wits. The Aries individual likes to be straightforward, and thus doesn't shy away from speaking the harshest truth. Meanwhile, the Scorpio natives think before they speak and are not up for straightforwardness as they believe that there is always a mature way to guide someone. Communication between Aries and Scorpio couple is surely difficult.
                                                    </p>
                                                    <div className='circle-box'>
                                                        <CircularProgressbar value={49} maxValue={100} text={`49%`} strokeWidth={10} counterClockwise={true}
                                                            styles={buildStyles({
                                                                strokeLinecap: 'rounded',
                                                                textSize: '22px',
                                                                pathColor: `#3EA9F5`,
                                                                textColor: '#3EA9F5',
                                                                trailColor: '#fff',
                                                            })} />
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <SidebarCardComponent />
                                <VerticalAppBanner />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{ width: '100%' }} className='mt-16 compatibility-section'>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <div className='compatibility relationship-tip'>
                                    <Typography variant='h5' className='title'>Relationship Tips</Typography>
                                    <p>The Aries and Libra compatibility is mostly like the explosion of emotions. Only a few Aries and Scorpio couples could proudly say that they have made their relationship work in the long run. And the one those who have are to truly enjoy a great time together. The fire and water though rarely meet, but if handled with care, it can turn into a full-fledged and beautiful relationship.</p>
                                </div>
                            </Grid>
                        </Grid>
                    </Box>
                    <AstrologerComponent />
                </main>
            </React.Fragment>
        );
    }
}

export default MarriageCompatibilityDetails;