import * as React from 'react';
import "../appBanners/verticalAppBanner.css";
import Grid from '@mui/material/Grid';
import appScanner from "../../images/app-scanner.svg";
import mobileApp from "../../images/mobile-app-2.png";
import googlePlayStore from "./../../images/google-play-store.svg";
import applePlayStore from "./../../images/apple-play-store.svg";
import { Link } from '@mui/material';

interface VerticalAppBannerProps {

}

interface VerticalAppBannerState {

}

class VerticalAppBanner extends React.Component<VerticalAppBannerProps, VerticalAppBannerState> {
    constructor(props: VerticalAppBannerProps) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <React.Fragment>
                <Grid className='aside-app-banner-box'>
                    <Grid item xs={12}>
                        <h2>App Services</h2>
                        <img src={appScanner} alt="app scanner" width={152} height={150} />
                    </Grid>
                    <Grid item xs={12} className='banner-bottom'>
                        <h1 className='download-title'>Click below link to download</h1>
                        <img src={mobileApp} alt="mobile app" />
                        <div className='app-playstore-img'>
                            <Link href="/">
                                <img src={googlePlayStore} alt='footer logo' width={224} height={66} />
                            </Link>
                            <Link href="/">
                                <img src={applePlayStore} alt='footer logo' width={224} height={70} />
                            </Link>

                        </div>

                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}

export default VerticalAppBanner;