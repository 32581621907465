import * as React from 'react';
import "../astrologyVideo/astrologyVideoCard.css";
import { Link } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import videoThumb from "../../images/astrology-video-thumb.png";
import YouTubeIcon from '../../icons/youtube-rectangle.svg';

interface AstrologyVideoCardProps {

}

interface AstrologyVideoCardState {

}

class AstrologyVideoCard extends React.Component<AstrologyVideoCardProps, AstrologyVideoCardState> {
    constructor(props: AstrologyVideoCardProps) {
        super(props);
        this.state = {

        };
    }
    render() {
        return (
            <React.Fragment>
                <Card className='astro-video-card'>
                    <CardContent className='astro-video-card-content'>
                        <Link href="/">
                            <div className='astro-video-thumb'>
                                <img src={videoThumb} alt="blog" width={270} height={175} style={{ maxWidth: "100%", height: "auto" }} />
                                <div className='card-overlay'>
                                    <img src={YouTubeIcon} alt="youtube icon" width={54} height={35} />
                                </div>
                            </div>
                            <div className='astro-video-cardbody'>
                                <h6 className='blog-card-title'>Lorem Ipsum is simply dummy text of the printing and typesetting industry printing. </h6>
                            </div>
                        </Link>
                    </CardContent>
                </Card>
            </React.Fragment>
        );
    }
}

export default AstrologyVideoCard;