import * as React from 'react';
import "./../blogs/bloglist.css";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Link, Typography } from '@mui/material';
import HorizantalTopBanner from '../appBanners/HorizantalTopBanner';
import SidebarCardComponent from '../sidebarCard/SidebarCardComponent';
import VerticalAppBanner from '../appBanners/VerticalAppBanner';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import blogImg1 from "../../images/blog-1.png";
import blogImg2 from "../../images/blog-2.png";
import blogImg3 from "../../images/blog-3.png";
import blogImg4 from "../../images/blog-4.png";
import blogImg5 from "../../images/blog-5.png";
import blogImg6 from "../../images/blog-6.png";
import blogImg7 from "../../images/blog-7.png";
import blogImg8 from "../../images/blog-8.png";
import VisibilityIcon from '@mui/icons-material/Visibility';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';


interface BlogListComponentProps {

}

interface BlogListComponentState {
    title: String;
    blogData: Array<BlogDataSate>;
}

interface BlogDataSate {
    thumbImg: any;
    title: String;
    author: String;
    date: any;
    views: number;
}

class BlogListComponent extends React.Component<BlogListComponentProps, BlogListComponentState> {
    constructor(props: BlogListComponentProps) {
        super(props);
        this.state = {
            title: "Latest Blog",
            blogData: [
                { thumbImg: blogImg1, title: "Lorem Ipsum is simply dummy text of the printing and typesetting industry printing.", author: "Admin", date: "Jul 13, 2023", views: 223 },
                { thumbImg: blogImg4, title: "Lorem Ipsum is simply dummy text of the printing and typesetting industry printing.", author: "Admin", date: "Jul 13, 2023", views: 223 },
                { thumbImg: blogImg3, title: "Lorem Ipsum is simply dummy text of the printing and typesetting industry printing.", author: "Admin", date: "Jul 13, 2023", views: 223 },
                { thumbImg: blogImg2, title: "Lorem Ipsum is simply dummy text of the printing and typesetting industry printing.", author: "Admin", date: "Jul 13, 2023", views: 223 },
                { thumbImg: blogImg5, title: "Lorem Ipsum is simply dummy text of the printing and typesetting industry printing.", author: "Admin", date: "Jul 13, 2023", views: 223 },
                { thumbImg: blogImg6, title: "Lorem Ipsum is simply dummy text of the printing and typesetting industry printing.", author: "Admin", date: "Jul 13, 2023", views: 223 },
                { thumbImg: blogImg7, title: "Lorem Ipsum is simply dummy text of the printing and typesetting industry printing.", author: "Admin", date: "Jul 13, 2023", views: 223 },
                { thumbImg: blogImg8, title: "Lorem Ipsum is simply dummy text of the printing and typesetting industry printing.", author: "Admin", date: "Jul 13, 2023", views: 223 },
            ]
        };
    }
    render() {
        return (
            <React.Fragment>
                <main>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item md={9} xs={12}>
                                <HorizantalTopBanner />
                                <Box className='blog-list-wrap mt-16'>
                                    <Typography variant='h4' className='heading-wtbg'>{this.state.title}</Typography>
                                    <Grid container spacing={2} className='mb-40'>
                                        {
                                            this.state.blogData.map((blogData, index) => {
                                                return <Grid item sm={4} xs={12} key={index}>
                                                    <Card className='blog-card'>
                                                        <CardContent className='blog-card-content'>
                                                            <Link href="/blog-details">
                                                                <img src={blogData.thumbImg} alt="blog" width={351} height={201} />
                                                                <div className='blog-cardbody'>
                                                                    <h6 className='blog-card-title'>{blogData.title}</h6>
                                                                    <div className='date-author-box'>
                                                                        <span>{blogData.author}</span>
                                                                        <span>{blogData.date}</span>
                                                                    </div>
                                                                </div>
                                                                <div className='card-overlay'>
                                                                    <VisibilityIcon fontSize='small' /> {blogData.views}
                                                                </div>
                                                            </Link>
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                            })
                                        }

                                        {/* <Grid item md={4} xs={12}>
                                            <Card className='blog-card'>
                                                <CardContent className='blog-card-content'>
                                                    <Link href="/">
                                                        <img src={blogImg1} alt="blog" width={351} height={201} style={{ maxWidth: "100%", height: "auto" }} />
                                                        <div className='blog-cardbody'>
                                                            <h6 className='blog-card-title'>Lorem Ipsum is simply dummy text of the printing and typesetting industry printing. </h6>
                                                            <div className='date-author-box'>
                                                                <span>Admin</span>
                                                                <span>Jul 13, 2023</span>
                                                            </div>
                                                        </div>
                                                        <div className='card-overlay'>
                                                            <VisibilityIcon fontSize='small' /> 223
                                                        </div>
                                                    </Link>
                                                </CardContent>
                                            </Card>
                                        </Grid> */}
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item md={12}>
                                            <div className='custom-pagination'>
                                                <Stack spacing={2}>
                                                    <Pagination
                                                        count={10}
                                                        shape='rounded'
                                                        siblingCount={0}
                                                        defaultPage={1}
                                                        boundaryCount={1}
                                                        renderItem={(item) => (
                                                            <PaginationItem
                                                                slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                                                                {...item}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <SidebarCardComponent />
                                <VerticalAppBanner />
                            </Grid>
                        </Grid>
                    </Box>
                </main>
            </React.Fragment>
        );
    }
}

export default BlogListComponent;