import * as React from 'react';
import "./../freeKundali-list/freeKundaliListing.css";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Button, Typography, InputLabel, TextField, FormControl, RadioGroup, FormControlLabel, Radio, MenuItem } from '@mui/material';
import HorizantalTopBanner from '../appBanners/HorizantalTopBanner';
import SidebarCardComponent from '../sidebarCard/SidebarCardComponent';
import VerticalAppBanner from '../appBanners/VerticalAppBanner';
import kundaliImg from "./../../images/kundali.svg";
import TodayDealsComponent from '../todayDeals/TodayDealsComponent';


interface FreeKundaliListingProps {
}

interface FreeKundaliListingState {
    title: String
    textData: Array<KundaliDescState>;
    createKundaliTitle: String;
    createKundaliDesc: Array<KundaliDescState>;

}
interface KundaliDescState {
    descText: String;
}


const DateList = [
    {
        value: '1',
        label: '1',
    },
    {
        value: '2',
        label: '2',
    },
    {
        value: '3',
        label: '3',
    },
    {
        value: '4',
        label: '4',
    },
];
class FreeKundaliListing extends React.Component<FreeKundaliListingProps, FreeKundaliListingState> {
    constructor(props: FreeKundaliListingProps) {
        super(props);
        this.state = {
            title: "Free Kundli",
            textData: [
                { descText: "Looking for your free Kundli from expert astrologers? Then you have come to the right place. The online free kundli available on AstroConsult is a 100% free and authentic free Kundli that has been prepared after consulting more than 50 expert astrologers on board. The free kundli is such that it can give you a glimpse into various aspects of your life such as your career, love life, marriage, business and much more. The online kundli prepared by the free Kundli software here is no less than any traditional Kundli and can also be used for purposes like matching making, kundli matching for marriage or simply making future predictions." },
                { descText: "Talking about future predictions, the kundli catered by AstroConsult to you is such that it considers the movement of all the planets in the native's life from the beginning of his or her life till as far as 100 years. Doing so helps you understand the reasons behind not only the ongoing circumstances but also what's to come for you in the future. So if in any way you are confused about life, the online kundli can be your saviour. Having said that, make sure you try the free online kundli, and let us know what you feel about it." }
            ],
            createKundaliTitle: "Create Online Janam Kundli",
            createKundaliDesc: [
                { descText: "What is Janam Kundli? Kundli is a chart prepared in astrology depending on the precise date, place, and time of birth of an individual. It figures out the placement of all the planets and signs, along with the Sun and Moon at the time of your birth." },
                { descText: "This free Janam Kundali by date of birth and time is effective only when you have exact information. Usually, people make mistake by putting wrong birth time, which eventually gives them the incorrect Kundali with absolutely irrelevant predictions. Check your Kundli chart now and prepare yourself for the upcoming adventures. Online Janam Kundli is easy to make. All you need is to put the right information and click on the submit button. Kundli prediction has its roots back from the Vedic times. We have made sure that our free Kundali chart does every justice to the Vedic tradition." },
                { descText: "AstroSage's free Kundli software provides more than 50 pages report, which covers almost all the aspects of your life. Kundli download is also not very difficult. Just find the download pdf button in your left options after making your detailed Kundali and click on it.If you manage to find a good website, checking Janam Kundli online is always a good idea. After all, astrology is like mathematics, and computer has lesser chances of making mathematical errors. We have various other free services that you may either explore on our website of mobile app. Free online Kundali software offers answers to the general queries, that is why we have got a panel of astrologers who answer your personalized question as a paid service." },
                { descText: "Online Kundli in Hindi has been most popular among all the languages we offer. Hence, we added a lot of content in Hindi language as per the requirement of our Hindi speaking users. Kundali making in Hindi is very simple. This free Janam Kundli in Hindi is no less from any other language. So, get your free Janam Kundali in Hindi now." },
            ]

        };
    }
    render() {
        return (<React.Fragment>
            <main>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                        <Grid item md={9} xs={12}>
                            <HorizantalTopBanner />
                            <Box sx={{ flexGrow: 1 }} className='astrology-section'>
                                <Grid container spacing={12} className='mb-45'>
                                    <Grid item md={7}>
                                        <Typography variant="h4" className='heading-title'>{this.state.title}</Typography>
                                        {
                                            this.state.textData.map((textP, index) => {
                                                return <Typography variant='body1' key={index} className='text-justify'>
                                                    {textP.descText}
                                                </Typography>
                                            })
                                        }
                                    </Grid>
                                    <Grid item md={5}>
                                        <div className='d-flex align-items-center h-100'>
                                            <img src={kundaliImg} alt="kundali" width={456} height={456} style={{ maxWidth: "100%", height: "auto" }} />
                                        </div>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box sx={{ flexGrow: 1, height: "auto" }} className='kundali-bgbox mt-16'>
                                <Grid container spacing={2} justifyContent="center">
                                    <Grid item xs={12} md={8}>
                                        <Typography variant="h3" className='heading-2 text-center p-3'>Create free kundali</Typography>
                                        <div className='kundali-forms'>
                                            <form action='' autoComplete="off" >
                                                <InputLabel htmlFor="name">Enter Birth Details</InputLabel>
                                                <TextField type='text' fullWidth size='small' placeholder='Enter Name' id="name" name='name' value="" />
                                                <FormControl>
                                                    <RadioGroup row aria-labelledby="gender" name="gender">
                                                        <FormControlLabel value="female" control={<Radio />} label="Female" />
                                                        <FormControlLabel value="male" control={<Radio />} label="Male" />
                                                        <FormControlLabel value="other" control={<Radio />} label="Rather Not Say" />
                                                    </RadioGroup>
                                                </FormControl>
                                                <div className='row'>
                                                    <Grid item md={4} sm={12}>
                                                        <InputLabel htmlFor="date">Birthday Date <sup>*</sup></InputLabel>
                                                        <TextField id="date" select size='small' fullWidth placeholder='Select Date'>

                                                            {DateList.map((option) => (
                                                                <MenuItem key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </Grid>
                                                    <Grid item md={4} sm={12}>
                                                        <InputLabel htmlFor="month">Month<sup>*</sup></InputLabel>
                                                        <TextField id="month" select size='small' fullWidth placeholder='Select Month'>

                                                            {DateList.map((option) => (
                                                                <MenuItem key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </Grid>
                                                    <Grid item sm={4}>
                                                        <InputLabel htmlFor="year">Year<sup>*</sup></InputLabel>
                                                        <TextField id="year" select size='small' fullWidth placeholder='Select Year'>

                                                            {DateList.map((option) => (
                                                                <MenuItem key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </Grid>
                                                </div>
                                                <div className='row'>
                                                    <Grid item md={4} sm={12}>
                                                        <InputLabel htmlFor="hour">Birthday Hour <sup>*</sup></InputLabel>
                                                        <TextField id="hour" select size='small' fullWidth placeholder='Select Hour'>

                                                            {DateList.map((option) => (
                                                                <MenuItem key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </Grid>
                                                    <Grid item md={4}>
                                                        <InputLabel htmlFor="minute">Minute<sup>*</sup></InputLabel>
                                                        <TextField id="minute" select size='small' fullWidth placeholder='Select Minute'>

                                                            {DateList.map((option) => (
                                                                <MenuItem key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </Grid>
                                                    <Grid item md={4}>
                                                        <InputLabel htmlFor="second">Second<sup>*</sup></InputLabel>
                                                        <TextField id="second" select size='small' fullWidth placeholder='Select Second'>

                                                            {DateList.map((option) => (
                                                                <MenuItem key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </Grid>
                                                </div>
                                                <InputLabel htmlFor="birthPlace">Birth Place<sup>*</sup></InputLabel>
                                                <TextField type='text' fullWidth size='small' placeholder='Enter Your Birth Place' id="birthPlace" name='birthPlace' value="" />
                                                <div className='d-flex justify-content-center'>
                                                    <Button type='submit' className='theme-btn btn-submit' >Submit</Button>
                                                </div>
                                            </form>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <SidebarCardComponent />
                            <VerticalAppBanner />
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ flexGrow: 1 }} className='astrology-section'>
                    <Grid container spacing={2} className='mb-4'>
                        <Grid item md={12}>
                            <Typography variant="h4" className='heading-title'>{this.state.createKundaliTitle}</Typography>
                            {
                                this.state.createKundaliDesc.map((textP, index) => {
                                    return <Typography variant='body1' key={index} className='text-justify'>
                                        {textP.descText}
                                    </Typography>
                                })
                            }
                        </Grid>
                    </Grid>
                </Box>
                <TodayDealsComponent />
            </main>
        </React.Fragment>);
    }
}

export default FreeKundaliListing;