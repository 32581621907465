import * as React from 'react';
import "./../panchang/panchang.css";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Button, Link, Typography } from '@mui/material';
import HorizantalTopBanner from '../appBanners/HorizantalTopBanner';
import SidebarCardComponent from '../sidebarCard/SidebarCardComponent';
import VerticalAppBanner from '../appBanners/VerticalAppBanner';
import Table from 'react-bootstrap/Table';
import panchangamImg from "./../../images/panchangam.svg";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Alert from 'react-bootstrap/Alert';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LiveChart from "./../../images/live-chart.svg";


interface PanchangComponentProps {

}

interface PanchangComponentState {
    title: String;
    textData: Array<PanchangDescState>;
    locations: any;
    date: String;
}
interface PanchangDescState {
    descText: String;
}

const locationList = [
    { name: "Mumbai Maharashtra" },
    { name: "New Delhi, Delhi, India" },
    { name: "Delhi, Indien, Nationales Hauptstadtterritorium Delhi" },
    { name: "Delhi, Nationales Hauptstadtterritorium Delhi, Indien" },
    { name: "Delhi, Louisiana, United States" },
    { name: "Delhi Hills, Ohio, United States" },
]
class PanchangComponent extends React.Component<PanchangComponentProps, PanchangComponentState> {
    constructor(props: PanchangComponentProps) {
        super(props);
        this.state = {
            title: "Today’s Panchang: Panchangam",
            textData: [
                { descText: "Panchang is the astrological daily calendar based on the Indian calendar. Daily panchang is one of the most sought Vedic astrology concepts, which conceptualise the day's planetary position to determine auspicious tithis, timing, festivals, vrats, etc. Using this information, a person can choose whether or not to begin or accomplish a particular task after carefully studying the favourability or unfavorability of the planets ruling the particular task. It is a belief among people that doing a thing or two as per Today's panchang timings brings good luck and helps in the accomplishment of the tasks with ease." },
                { descText: "At Astrotalk, a Panchang calendar is produced by a team of the best astrologers of India. The Daily Panchang details the various astrological elements of the day and based on the same, the level of auspiciousness and inauspiciousness is decided. Right from Today's sunrise time to today's sunset time, Panchang helps you find all of it as per your convenience." },
                { descText: "Majorly, Panchang is used to get detail of five aspects. These five aspects are - day of the week (vaar); tithi or the lunar day; nakshatra or the constellation; yoga; and karan. With the change in time and date, all of these compartments that make the panchang also change. And thus it’s necessary to keep oneself updated about them for various reasons." }
            ],
            locations: "",
            date: ""
        };
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange = (e: any) => {
        e.preventDefault()
        this.setState({
            [e.target.name]: e.target.value
        } as PanchangComponentProps as PanchangComponentState)
    }
    render() {
        return (
            <React.Fragment>
                <main>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item md={9} xs={12}>
                                <HorizantalTopBanner />
                                <Box sx={{ flexGrow: 1 }} className='astrology-section'>
                                    <Grid container spacing={{ xs: 2, lg: 6 }} className='mb-40'>
                                        <Grid item md={8}>
                                            <Typography variant="h4" className='heading-title text-capitalize mb-4'>{this.state.title}</Typography>
                                            {
                                                this.state.textData.map((textP, index) => {
                                                    return <Typography variant='body1' key={index} className='text-justify pb-1'>
                                                        {textP.descText}
                                                    </Typography>
                                                })
                                            }
                                        </Grid>
                                        <Grid item md={4}>
                                            <div className='d-flex align-items-center h-100'>
                                                <img src={panchangamImg} alt="panchangam" width={362} height={320} style={{ maxWidth: "100%", height: "auto" }} />
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Box sx={{ flexGrow: 1 }} className='location-box mb-40'>
                                        <Grid container spacing={2} >
                                            <Grid item xs={12}>
                                                <p className='box-heading'>Enter Location & Date</p>
                                                <form action="">
                                                    <Grid container spacing={2}>
                                                        <Grid item md={5} sm={4} xs={12}>
                                                            <Autocomplete id="locations" fullWidth size='small'
                                                                options={locationList.map((option) => option.name)}
                                                                renderInput={(params) => (
                                                                    <TextField {...params} placeholder='Enter location' name='locations' onChange={this.handleChange} />
                                                                )}
                                                            />
                                                        </Grid>
                                                        <Grid item md={5} sm={4} xs={12}>
                                                            <TextField type='date' fullWidth size='small' placeholder='dd/mm/yy' id="date" name='date' value={this.state.date} onChange={this.handleChange} />
                                                        </Grid>
                                                        <Grid item md={2} sm={4} xs={12}>
                                                            <Button type='submit' className='theme-btn text-capitalize d-block h-100' >Get Panchang</Button>
                                                        </Grid>
                                                    </Grid>
                                                </form>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box sx={{ flexGrow: 1 }}>
                                        <Grid container spacing={2} >
                                            <Grid item xs={12}>
                                                <Grid container spacing={4}>
                                                    <Grid item md={12}>
                                                        <div className='panchang-box-heading'>
                                                            <Typography variant='h4'>Today’s Panchang</Typography>
                                                            <Typography component="div">
                                                                <span className='date'>November 8, Wednesday</span>
                                                                <span className='locations'>New Delhi, Delhi, India</span>
                                                            </Typography>
                                                        </div>
                                                    </Grid>
                                                    <Grid item md={4} sm={6} xs={12}>
                                                        <Typography component="div" className='sunset-moonset'>
                                                            <Typography variant='h6'>Sunrise - Sunset</Typography>
                                                            {['warning'].map((variant) => (
                                                                <Alert key={variant} variant={variant}>
                                                                    <LightModeIcon className='sunset-icon' />
                                                                    <Typography>05:37 - 19:17</Typography>
                                                                </Alert>
                                                            ))}
                                                        </Typography>
                                                        <Typography component="div" className='sunset-moonset'>
                                                            <Typography variant='h6'>Moonrise - Moonset</Typography>
                                                            {['info'].map((variant) => (
                                                                <Alert key={variant} variant={variant}>
                                                                    <DarkModeIcon className='moon-icon' />
                                                                    <Typography>10:37 - 22:17</Typography>
                                                                </Alert>
                                                            ))}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item md={8} sm={6} xs={12}>
                                                        <div className='panchang-table'>
                                                            <Table striped hover responsive>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Tithi</td>
                                                                        <td>Ekadashi</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Nakshatra</td>
                                                                        <td>Uttara Phalguni upto 07:58</td>
                                                                    </tr>
                                                                </tbody>
                                                            </Table>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <SidebarCardComponent />
                                <VerticalAppBanner />
                            </Grid>
                        </Grid>
                    </Box>
                    <section className="mt-16">
                        <Box sx={{ width: '100%' }} className='heading mt-16'>
                            <Typography variant="h2" className='heading-title'>Muhurat</Typography>
                            <Link href="/" className='heading-link'>See all</Link>
                        </Box>
                        <Box sx={{ flexGrow: 1 }} className="muhurt-box">
                            <Grid container spacing={2}>
                                <Grid item md={6} xs={12}>
                                    <h5 className='muhurt-title'>Inauspicious Timings (Ashubha Muhurat)</h5>
                                    <div className='muhurt-table'>
                                        <Table striped hover responsive>
                                            <tbody>
                                                <tr>
                                                    <td>Tithi</td>
                                                    <td>Ekadashi</td>
                                                </tr>
                                                <tr>
                                                    <td>Nakshatra</td>
                                                    <td>Uttara Phalguni upto 07:58</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <h5 className='muhurt-title'>Auspicious Timings (Shubha Muhurat)</h5>
                                    <div className='muhurt-table'>
                                        <Table striped hover responsive>
                                            <tbody>
                                                <tr>
                                                    <td>Tithi</td>
                                                    <td>Ekadashi</td>
                                                </tr>
                                                <tr>
                                                    <td>Nakshatra</td>
                                                    <td>Uttara Phalguni upto 07:58</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </Grid>
                            </Grid>
                        </Box>
                    </section>
                    <Box sx={{ flexGrow: 1 }} className="mt-16 overflow-hidden">
                        <Grid container spacing={2}>
                            <Grid item md={6} xs={12}>
                                <Box sx={{ width: '100%' }} className='heading'>
                                    <Typography variant="h2" className='heading-title'>Disha Shoola</Typography>
                                </Box>
                                <div className='muhurt-box'>
                                    <div className='muhurt-table'>
                                        <Table striped hover responsive>
                                            <tbody>
                                                <tr>
                                                    <td>Disha Shoola</td>
                                                    <td>North</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Box sx={{ width: '100%' }} className='heading'>
                                    <Typography variant="h2" className='heading-title'>Chandrabalam And Tarabalam</Typography>
                                </Box>
                                <div className='muhurt-box'>
                                    <div className='muhurt-table'>
                                        <Table striped hover responsive>
                                            <tbody>
                                                <tr>
                                                    <td>Tara Bala</td>
                                                    <td>Bharani, Rohini, Mrigashirsha, Ardra, Punarvasu, Ashlesha, Poorva Phalguni, Hasta, Chitra, Swati, Vishakha, Jyeshta, Poorva Ashadha, Shravana, Dhanishta, Satabisha, Poorva Bhadrapada, Revati</td>
                                                </tr>
                                                <tr>
                                                    <td>Chandra Bala</td>
                                                    <td>Vrishabha, Karka, Simha, Vrishchika, Dhanu, Meena</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{ flexGrow: 1 }} className="mt-16">
                        <Grid container spacing={2}>
                            <Grid item md={5} xs={12}>
                                <Box sx={{ width: '100%' }} className='heading'>
                                    <Typography variant="h2" className='heading-title'>Lagna Chart</Typography>
                                </Box>
                                <Box className="lagan-chart">
                                    <img src={LiveChart} alt="live chart" width={527} height={520} style={{ height: "auto", maxWidth: "100%" }} />
                                </Box>
                            </Grid>
                            <Grid item md={7} xs={12}>
                                <Box sx={{ width: '100%' }} className='heading'>
                                    <Typography variant="h2" className='heading-title'>Planetary Position</Typography>
                                </Box>
                                <div className='planetary-table'>
                                    <Table striped hover responsive>
                                        <thead>
                                            <tr>
                                                <th>Planets</th>
                                                <th>Rashi</th>
                                                <th>Longitude</th>
                                                <th>Nakshatra</th>
                                                <th>Pada</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Sun</td>
                                                <td>Sagittarius</td>
                                                <td>09-49-03</td>
                                                <td>Mula</td>
                                                <td>3</td>
                                            </tr>
                                            <tr>
                                                <td>Sun</td>
                                                <td>Sagittarius</td>
                                                <td>09-49-03</td>
                                                <td>Mula</td>
                                                <td>3</td>
                                            </tr>
                                            <tr>
                                                <td>Sun</td>
                                                <td>Sagittarius</td>
                                                <td>09-49-03</td>
                                                <td>Mula</td>
                                                <td>3</td>
                                            </tr>
                                            <tr>
                                                <td>Sun</td>
                                                <td>Sagittarius</td>
                                                <td>09-49-03</td>
                                                <td>Mula</td>
                                                <td>3</td>
                                            </tr>
                                            <tr>
                                                <td>Sun</td>
                                                <td>Sagittarius</td>
                                                <td>09-49-03</td>
                                                <td>Mula</td>
                                                <td>3</td>
                                            </tr>
                                            <tr>
                                                <td>Sun</td>
                                                <td>Sagittarius</td>
                                                <td>09-49-03</td>
                                                <td>Mula</td>
                                                <td>3</td>
                                            </tr>
                                            <tr>
                                                <td>Sun</td>
                                                <td>Sagittarius</td>
                                                <td>09-49-03</td>
                                                <td>Mula</td>
                                                <td>3</td>
                                            </tr>
                                            <tr>
                                                <td>Sun</td>
                                                <td>Sagittarius</td>
                                                <td>09-49-03</td>
                                                <td>Mula</td>
                                                <td>3</td>
                                            </tr>
                                            <tr>
                                                <td>Sun</td>
                                                <td>Sagittarius</td>
                                                <td>09-49-03</td>
                                                <td>Mula</td>
                                                <td>3</td>
                                            </tr>
                                            <tr>
                                                <td>Sun</td>
                                                <td>Sagittarius</td>
                                                <td>09-49-03</td>
                                                <td>Mula</td>
                                                <td>3</td>
                                            </tr>
                                            <tr>
                                                <td>Sun</td>
                                                <td>Sagittarius</td>
                                                <td>09-49-03</td>
                                                <td>Mula</td>
                                                <td>3</td>
                                            </tr>
                                            <tr>
                                                <td>Sun</td>
                                                <td>Sagittarius</td>
                                                <td>09-49-03</td>
                                                <td>Mula</td>
                                                <td>3</td>
                                            </tr>
                                            <tr>
                                                <td>Sun</td>
                                                <td>Sagittarius</td>
                                                <td>09-49-03</td>
                                                <td>Mula</td>
                                                <td>3</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </Grid>
                        </Grid>
                    </Box>
                </main>
            </React.Fragment>
        );
    }
}

export default PanchangComponent;