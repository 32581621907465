import * as React from 'react';
import "../freeKundali/freeKundali.css";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import { Button } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';

interface FreeKundaliComponentProps {

}
interface FreeKundaliComponentState {
    name: String;
    gender: String;
    date: String;
    time: String;
    birthPlace: any;

}

const locations = [
    { place: "Mumbai Maharashtra" },
    { place: "New Delhi, Delhi, India" },
    { place: "Delhi, Indien, Nationales Hauptstadtterritorium Delhi" },
    { place: "Delhi, Nationales Hauptstadtterritorium Delhi, Indien" },
    { place: "Delhi, Louisiana, United States" },
    { place: "Delhi Hills, Ohio, United States" },
]
export default class FreeKundaliComponent extends React.Component<FreeKundaliComponentProps, FreeKundaliComponentState>{
    constructor(props: FreeKundaliComponentProps) {
        super(props);
        this.state = {
            name: "",
            gender: "",
            date: "",
            time: "",
            birthPlace: ""


        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = (e: any) => {
        e.preventDefault()
        this.setState({
            [e.target.name]: e.target.value
        } as FreeKundaliComponentProps as FreeKundaliComponentState)
    }
    render() {
        return (
            <React.Fragment>
                <Box sx={{ flexGrow: 1 }} className='kundali-bgbox'>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h3" className='heading-2 text-center p-3'>Free Kundali</Typography>
                            <div className='kundali-forms'>
                                <form action=''>
                                    <InputLabel htmlFor="name">Enter Birth Details</InputLabel>
                                    <TextField type='text' fullWidth size='small' placeholder='Enter Name' id="name" name='name' value={this.state.name} onChange={this.handleChange} />
                                    <FormControl>
                                        <RadioGroup row aria-labelledby="gender" name="gender" value={this.state.gender}
                                            onChange={this.handleChange}>
                                            <FormControlLabel value="female" control={<Radio />} label="Female" />
                                            <FormControlLabel value="male" control={<Radio />} label="Male" />
                                            <FormControlLabel value="other" control={<Radio />} label="Rather Not Say" />
                                        </RadioGroup>
                                    </FormControl>
                                    <TextField type='date' fullWidth size='small' placeholder='dd/mm/yy' id="date" name='date' value={this.state.date} onChange={this.handleChange} />
                                    <TextField type='time' fullWidth size='small' placeholder='hh/mm/ss' id="time" name='time' value={this.state.time} onChange={this.handleChange} />
                                    {/* <TextField type='text' fullWidth size='small' placeholder='Enter Your Birth Place' id="birthPlace" name='birthPlace' value={this.state.birthPlace} onChange={this.handleChange} /> */}
                                    <Autocomplete fullWidth id="birthPlace" size='small'
                                        options={locations.map((option) => option.place)}
                                        renderInput={(params) => (
                                            <TextField {...params} placeholder='Enter Your Birth Place' name='birthPlace' />
                                        )}
                                    />

                                    <div className='d-flex justify-content-center'>
                                        <Button type='submit' className='theme-btn btn-submit' >Submit</Button>
                                    </div>
                                </form>
                            </div>
                        </Grid>
                    </Grid>
                </Box>
            </React.Fragment>
        )
    }
}