import * as React from 'react';
import "../astrology/astrology.css";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import astrologyImg from "../../images/astrology.png";




interface AstrologyComponentProps {

}

interface AstrologyComponentState {
    title: String,
    subTitle: String;
    desc: Array<AstrologyDesc>;
}

interface AstrologyDesc {
    descText: String;
}


class AstrologyComponent extends React.Component<AstrologyComponentProps, AstrologyComponentState> {
    constructor(props: AstrologyComponentProps) {
        super(props);
        this.state = {
            title: "why astrology ?",
            subTitle: "Astrology reveals the will of the God",
            desc: [
                { descText: "Astrology is a belief system that suggests a relationship between the positions and movements of celestial bodies (such as planets and stars) and events on Earth, as well as human characteristics and behaviors. It is a form of divination that is based on the idea that the positions and movements of celestial bodies can influence human affairs and the natural world." },
                { descText: "Astrology has been practiced in various forms for thousands of years in different cultures and societies around the world. Different traditions have their own methods and systems of interpreting celestial movements and their supposed effects on human life. Astrologers typically create horoscopes, which are charts or diagrams that represent the positions of the Sun, Moon, planets, and other celestial bodies at a specific time, such as a person's birth." },
                { descText: "It's important to note that astrology is not considered a science, as it lacks empirical evidence and a theoretical framework that can be tested and validated through the scientific method. While many people find meaning and guidance in astrology, it is essential to approach it with a critical and open mind, considering it as a tool for self-reflection and exploration rather than a concrete predictor of future events or personality traits." },
            ]

        };
    }
    render() {
        return (
            <React.Fragment>
                <Box sx={{ flexGrow: 1 }} className='astrology-section'>
                    <Grid container spacing={2}>
                        <Grid item md={7}>
                            <Typography variant="h4" className='heading-title'>{this.state.title}</Typography>
                            <Typography variant="h5" className='sub-heading'>{this.state.subTitle}</Typography>
                            {
                                this.state.desc.map(desctext => {
                                    return <Typography variant='body1'>
                                        {desctext.descText}
                                    </Typography>
                                })
                            }
                        </Grid>
                        <Grid item md={5}>
                            <div className='d-flex justify-content-center align-items-center h-100'>
                                <img src={astrologyImg} alt="astrology" width={456} height={456} style={{ maxWidth: "100%", height: "auto" }} />
                            </div>
                        </Grid>
                    </Grid>
                </Box>
            </React.Fragment>
        );
    }
}

export default AstrologyComponent;
