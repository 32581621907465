import * as React from 'react';
import "../horoscopeDetails/horoscopeDetails.css";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Button, Link, Typography, Breadcrumbs } from '@mui/material';
import HorizantalTopBanner from '../appBanners/HorizantalTopBanner';
import SidebarCardComponent from '../sidebarCard/SidebarCardComponent';
import VerticalAppBanner from '../appBanners/VerticalAppBanner';
import AstrologerComponent from '../astrologer/AstrologerComponent';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import hAries from './../../icons/h-aries.svg';
import hTaurus from './../../icons/h-taurus.svg';
import hGemini from './../../icons/gemini.svg';
import hCancer from './../../icons/cancer.svg';
import hLeo from './../../icons/leo.svg';
import hVirgo from './../../icons/virgo.svg';
import hLibra from './../../icons/libra.svg';
import hScorpio from './../../icons/scorpio.svg';
import hSagittarius from './../../icons/sagittarius.svg';
import hCapricorn from './../../icons/capricorn.svg';
import hAquarius from './../../icons/aquarius.svg';
import hPisces from './../../icons/pisces.svg';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Card from 'react-bootstrap/Card';
import heart from "./../../icons/heart.svg";
import money from "./../../icons/money-with-wings.svg";
import health from "./../../icons/healthcare.svg";
import career from "./../../icons/profile.svg";
import travel from "./../../icons/departure-airport.svg";
import quoteFrame from "./../../images/quote-frame.png";





interface HoroscopeDetailsComponentProps {

}

interface HoroscopeDetailsComponentState {
    index: number;
    value: any;
    horoscopeName: string;
    dayTime: string;
    horoscopeDesc: Array<HoroscopeTextState>;
    readMore: boolean;
    horoDtlData: Array<HoroscopeDtlCardState>;
    horoAboutData: Array<HoroscopeDetailsAboutState>;
    alsoCheck: Array<HoroscopeAlsoCheckState>;
}

interface HoroscopeTextState {
    desc: String;
}
interface HoroscopeDtlCardState {
    id: number;
    title: String;
    score: String;
    thumbnail: any;
    thumbnailTitle: any;
    desc: String;

}

interface HoroscopeDetailsAboutState {
    id: any;
    title: String;
    aboutText: String;
    aboutLists: Array<HoroscopeAboutListState>;
}
interface HoroscopeAboutListState {
    id: any;
    listItems: String;
}

interface HoroscopeAlsoCheckState {
    calendarPlan: String;
    title: String;
    text: String;
    link: any;
}

class HoroscopeDetailsComponent extends React.Component<HoroscopeDetailsComponentProps, HoroscopeDetailsComponentState> {
    constructor(props: HoroscopeDetailsComponentProps) {
        super(props);
        this.state = {
            index: 0,
            value: 0,
            horoscopeName: "Aries Daily Horoscope",
            readMore: false,
            dayTime: "Friday, November, 2023",
            horoscopeDesc: [
                { desc: "Aries is the first zodiac sign among the 12 Zodiac signs in existence and is said to be the starter of things. People with their zodiac sign is Aries are known for their tendency to go headfirst into the given situation. Aries signs’ element is Fire, which makes such people pretty bold in their attitude. They are the ones you can go to seek motivation in life. And even the smallest of pep talk you have with Aries will fill you with motivation like no other." },
                { desc: "While these are the most basic things about Aries, more details about the zodiac sign can be extracted from Aries daily horoscope. There is no better way to understand the sign other than reading the Aries horoscope." },
            ],
            horoDtlData: [
                { id: 1, title: "Love", score: "80%", thumbnail: heart, thumbnailTitle: "heart", desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. when an unknown printer took a galley of type and scrambled it to make a type specimen book." },
                { id: 2, title: "Career", score: "80%", thumbnail: career, thumbnailTitle: "Career", desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. when an unknown printer took a galley of type and scrambled it to make a type specimen book." },
                { id: 3, title: "Money", score: "80%", thumbnail: money, thumbnailTitle: "Money", desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. when an unknown printer took a galley of type and scrambled it to make a type specimen book." },
                { id: 4, title: "Travel", score: "80%", thumbnail: travel, thumbnailTitle: "Travel", desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. when an unknown printer took a galley of type and scrambled it to make a type specimen book." },
                { id: 5, title: "Health", score: "80%", thumbnail: health, thumbnailTitle: "Health", desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. when an unknown printer took a galley of type and scrambled it to make a type specimen book." },
            ],
            horoAboutData: [
                {
                    id: 1, title: "What is Aries Zodiac Sign?", aboutText: "Aries daily horoscope is based on the first sign of Kaal Purush Kundali and represents its first house. It represents the head of Kaal Purush. It is the first initial sign of Zodiac which commences at the vernal equinox and extends upto 30 degrees. It is fiery and hot in nature. It is titled as a Male sign and is masculine or positive in nature.",
                    aboutLists: [
                        { id: 1, listItems: "" }
                    ]
                },
                {
                    id: 2, title: "Aries people & Their Physical Appearance", aboutText: "Every sign in the zodiac belt reflects different traits, personality and appearance. Aries horoscope today will enable you to know about your daily actions and how you are going to express your thoughts through facial expressions. Let's check out what are the physical appearances denoted by Aries sign:",
                    aboutLists: [
                        { id: 1, listItems: "These people are of middle stature." },
                        { id: 2, listItems: "They have lean and muscular body." },
                        { id: 3, listItems: "They have ruddy complexion, fairly long neck and face." },
                        { id: 4, listItems: "They have wiry hair with Colour varying from dark to sandy." },
                        { id: 5, listItems: "They have a broad head and narrow chin." },
                        { id: 6, listItems: "They have bushy eyebrows with sharp eyesight." },
                    ]
                },
                {
                    id: 3, title: "Characteristics for Aries Born People", aboutText: "As Aries sign comes first in zodiac belt, it represents overall affairs of life. The person would have the desire to rule over every aspect of life. You would be able to know about your personality traits on daily basis through Aries horoscope today. Here are some characteristics defined for Aries sign people when analysing Aries horoscope:",
                    aboutLists: [
                        { id: 1, listItems: "They are those people who believe in their decisions and their own judgement. They do not entertain the suggestion of others as they are their own leader." },
                        { id: 2, listItems: "They always want to lead the thoughts and actions as they do not like to do work as a subordinate." },
                        { id: 3, listItems: "They are good leaders or can govern others in a well manner if the ascendant is being aspected by the benefic planets." },
                        { id: 4, listItems: "Due to the positiveness, it reflects determination and self confidence within a person. They are quick responders and learners." },
                        { id: 5, listItems: "As it is a movable sign, so they do not hesitate to change or replace if they do not like something." },
                        { id: 6, listItems: "They are the people who don't wait for opportunities, they go out and create them by themselves." },
                    ]
                }
            ],
            alsoCheck: [
                { calendarPlan: "Weekly", title: "Aries Weekly Horoscope", text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. when an unknown printer took a galley of type and scrambled it to make a type specimen book simply dummy text of the printing and industry...", link: "/" },
                { calendarPlan: "Monthly", title: "Aries Monthly Horoscope", text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. when an unknown printer took a galley of type and scrambled it to make a type specimen book simply dummy text of the printing and industry...", link: "/" },
                { calendarPlan: "Yearly", title: "Aries Yearly Horoscope", text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. when an unknown printer took a galley of type and scrambled it to make a type specimen book simply dummy text of the printing and industry...", link: "/" },
            ]


        }
    }
    handleChange = (e: React.ChangeEvent<any>) => {
        this.setState({ value: e.target.value });
    }
    handleClick = () => {
        this.setState({

        })
    }
    handleReadMore = () => {
        this.setState({
            readMore: !this.state.readMore
        })
    }
    render() {
        return (
            <React.Fragment>
                <main>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item md={9} xs={12}>
                                <HorizantalTopBanner />
                                <Box sx={{ width: '100%' }} className='heading custom-breadcrumb mt-16'>
                                    <Breadcrumbs separator={<NavigateNextIcon />} aria-label="breadcrumb">
                                        <Link href="/Horoscope">Horoscope</Link>
                                        <Typography>Aries Daily Horoscope </Typography>
                                    </Breadcrumbs>
                                </Box>
                                <Box sx={{ width: '100%' }} className="horoscope-tab">
                                    <Tabs defaultActiveKey="daily" id="horoscopeTab" className="mb-0" fill>
                                        <Tab eventKey="daily" title="Daily">
                                            <ul className='horoscope-lists'>
                                                <li className='active'>
                                                    <Button onClick={this.handleClick}><img src={hAries} alt='' /></Button>
                                                </li>
                                                <li>
                                                    <Button onClick={this.handleClick}><img src={hTaurus} alt='' /></Button>
                                                </li>
                                                <li>
                                                    <Button onClick={this.handleClick}><img src={hGemini} alt='' /></Button>
                                                </li>
                                                <li>
                                                    <Button onClick={this.handleClick}><img src={hCancer} alt='' /></Button>
                                                </li>
                                                <li>
                                                    <Button onClick={this.handleClick}><img src={hLeo} alt='' /></Button>
                                                </li>
                                                <li>
                                                    <Button onClick={this.handleClick}><img src={hVirgo} alt='' /></Button>
                                                </li>
                                                <li>
                                                    <Button onClick={this.handleClick}><img src={hLibra} alt='' /></Button>
                                                </li>
                                                <li>
                                                    <Button onClick={this.handleClick}><img src={hScorpio} alt='' /></Button>
                                                </li>
                                                <li>
                                                    <Button onClick={this.handleClick}><img src={hSagittarius} alt='' /></Button>
                                                </li>
                                                <li>
                                                    <Button onClick={this.handleClick}><img src={hCapricorn} alt='' /></Button>
                                                </li>
                                                <li>
                                                    <Button onClick={this.handleClick}><img src={hAquarius} alt='' /></Button>
                                                </li>
                                                <li>
                                                    <Button onClick={this.handleClick}><img src={hPisces} alt='' /></Button>
                                                </li>
                                            </ul>
                                            <div className='horoscope-contents'>
                                                <div className='htitle-date h-details-title-date'>
                                                    <h2>{this.state.horoscopeName}</h2>
                                                    <h6>{this.state.dayTime}</h6>
                                                </div>
                                                <Grid container spacing={2}>
                                                    <Grid item md={8}>
                                                        {
                                                            this.state.horoscopeDesc.map((horoDesc, index) => {
                                                                return <p className='horoscope-dtl-p' key={index}>
                                                                    {horoDesc.desc}

                                                                </p>
                                                            })
                                                        }
                                                    </Grid>
                                                    <Grid item md={4}>

                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={2}>
                                                    {
                                                        this.state.horoDtlData.map((heroDtlData) => {
                                                            return <Grid item md={6} xs={12} key={heroDtlData.id}>
                                                                <Card className="h-details-cards danger-card">
                                                                    <Card.Header>{heroDtlData.title} <span>{heroDtlData.score}</span></Card.Header>
                                                                    <Card.Body>
                                                                        <div className='card-icons'>
                                                                            <img src={heroDtlData.thumbnail} alt={heroDtlData.thumbnailTitle} />
                                                                        </div>
                                                                        <Card.Text>
                                                                            {heroDtlData.desc}
                                                                        </Card.Text>
                                                                    </Card.Body>
                                                                </Card>
                                                            </Grid>
                                                        })
                                                    }

                                                </Grid>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="weekly" title="Weekly">
                                            Tab content for Profile
                                        </Tab>
                                        <Tab eventKey="monthly" title="Monthly">
                                            Tab content for Loooonger Tab
                                        </Tab>
                                        <Tab eventKey="yearly" title="Yearly">
                                            Tab content for Contact
                                        </Tab>
                                    </Tabs>
                                </Box>

                            </Grid>
                            <Grid item md={3} xs={12}>
                                <SidebarCardComponent />
                                <VerticalAppBanner />
                            </Grid>
                        </Grid>
                    </Box>
                    <AstrologerComponent />
                    <Box sx={{ flexGrow: 1 }} className='horoscope-details-about mt-16'>
                        <Grid container spacing={2}>
                            <Grid item md={12}>
                                {
                                    this.state.horoAboutData.map((aboutData) => {
                                        return <div className='details-about-wrap' key={aboutData.id}>
                                            <Typography variant="h4" className='heading-title'>{aboutData.title}</Typography>
                                            <Typography variant='body1'>
                                                {aboutData.aboutText}
                                            </Typography>
                                            <ul>
                                                {
                                                    aboutData.aboutLists.map((aboutListItem) => {
                                                        return <li key={aboutListItem.id}>{aboutListItem.listItems}</li>
                                                    })
                                                }
                                            </ul>


                                        </div>
                                    })
                                }

                            </Grid>
                        </Grid>
                    </Box>

                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item md={9}>
                                <Box sx={{ width: '100%' }} className='heading mt-16'>
                                    <Typography variant="h2" className='heading-title'>Also check horoscope</Typography>
                                </Box>
                                <Box sx={{ width: '100%' }} className="alsocheck-box">
                                    <Grid container spacing={2}>
                                        {
                                            this.state.alsoCheck.map((alsoCheckData, index) => {
                                                return <Grid item md={4} className='alsocheck-col-grid' key={index}>
                                                    <h5>{alsoCheckData.calendarPlan}</h5>
                                                    <div className='alsocheck-inner-box'>
                                                        <Typography variant="h3">
                                                            {alsoCheckData.title}
                                                        </Typography>
                                                        <Typography variant="body1">
                                                            {alsoCheckData.text}
                                                        </Typography>
                                                        <Link href={alsoCheckData.link} className='btn default-btn m-auto  '>Read more</Link>
                                                    </div>
                                                </Grid>
                                            })
                                        }

                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item md={3}>
                                <Box sx={{ width: '100%' }} className='heading mt-16'>
                                    <Typography variant="h2" className='heading-title'>Aries Facts</Typography>
                                </Box>
                                <Box sx={{ width: '100%' }} className="horoscope-facts-box">
                                    <Grid container spacing={2}>
                                        <Grid item md={12}>
                                            <ul className='horoscope-facts'>
                                                <li><strong>Lucky number:</strong>9, 8 and 6</li>
                                                <li><strong>Lucky colors:</strong>Blue, Blue-green</li>
                                                <li><strong>Lucky Day:</strong>Friday, Tuesday and Saturday</li>
                                                <li><strong>Lucky Gemstone:</strong>Coral; and it is to be worn as ring in middle finger</li>
                                                <li><strong>Compatible Zodiac Signs:</strong>Gemini, Leo, Sagittarius, Aquarius</li>
                                                <li><strong>Incompatible Zodiac Signs:</strong>Taurus, Cancer, Virgo, Scorpio, Capricorn, Pisces</li>

                                            </ul>
                                        </Grid>

                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box className='mt-16'>
                        <Grid container spacing={2}>
                            <Grid item md={12}>
                                <div className='horoscope-details-quotes'>
                                    <div className='quote-box'>
                                        <img src={quoteFrame} alt="quotes" />
                                        <div className='quote-box-text'>
                                            <h1>Believe you can and you're halfway there.</h1>
                                            <p>Theodore Roosevelt</p>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Box>
                </main>
            </React.Fragment>
        );
    }
}

export default HoroscopeDetailsComponent;