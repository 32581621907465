import * as React from 'react';
import '../header/headerComponents.css';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link, InputAdornment, TextField } from '@mui/material';
import logo from '../../images/logo.svg';
import facebook from '../../icons/facebook.svg';
import twitter from '../../icons/twitter.svg';
import instagram from '../../icons/instagram.svg';
import youTube from '../../icons/youtube.svg';
import SearchIcon from '@mui/icons-material/Search';




interface HeaderComponentsProps {

}

interface HeaderComponentsState {
  isActive: Boolean;
}

class HeaderComponents extends React.Component<HeaderComponentsProps, HeaderComponentsState> {
  constructor(props: HeaderComponentsProps) {
    super(props);
    this.state = {
      isActive: true
    };

  }

  render() {
    return (
      <header>
        <Box className="header-top" sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item lg={3} md={3} sm={4}>
              <Link href="/">
                <img src={logo} width={333} height={80} alt='' />
              </Link>
            </Grid>
            <Grid item lg={6} md={6} sm={4}>
              <div className='search-box'>
                <TextField type='search' placeholder="With normal TextField" id="search" variant='filled' fullWidth
                  InputProps={{
                    startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                  }}
                />
              </div>
            </Grid>
            <Grid item lg={3} md={3} sm={4}>
              <ul className='social-icons'>
                <li>
                  <Link href="facebook"><img src={facebook} width={28} height={28} alt="facebook" /></Link>
                </li>
                <li>
                  <Link href="twi"><img src={twitter} width={28} height={28} alt="twitter" /></Link>
                </li>
                <li>
                  <Link href="ins"><img src={instagram} width={28} height={28} alt="instagram" /></Link>
                </li>
                <li>
                  <Link href="youtube"><img src={youTube} width={28} height={28} alt="youtube" /></Link>
                </li>
              </ul>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ flexGrow: 1 }} className="main-menu">
          <Grid container spacing={2} className='menu-wrap'>
            <Grid item md={12}>
              <Navbar expand="lg">
                <Navbar.Toggle aria-controls="main-nav" />
                <Navbar.Collapse id="main-nav">
                  <Nav className="me-auto">
                    <Nav.Link href="/home">Home</Nav.Link>
                    <Nav.Link href="/horoscope">Horoscope</Nav.Link>
                    <Nav.Link href="/free-kundali">Free Kundali</Nav.Link>
                    <Nav.Link href="/kundali-match">Kundli Matching</Nav.Link>
                    <Nav.Link href="/panchang">Panchang</Nav.Link>
                    <Nav.Link href="/blogs">Blogs</Nav.Link>
                    <NavDropdown title="Others">
                      <NavDropdown.Item href="#action/3.1">About</NavDropdown.Item>
                      <NavDropdown.Item href="#action/3.2">Contact</NavDropdown.Item>
                      <NavDropdown.Item href="#action/3.3">Privacy and Policy</NavDropdown.Item>
                      <NavDropdown.Item href="#action/3.4">Term and Conditions</NavDropdown.Item>
                    </NavDropdown>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
            </Grid>
          </Grid>
          <Link href="/login" className='theme-btn header-login-btn'>Sign In/Sign Up</Link>
        </Box>
      </header>
    );
  }
}

export default HeaderComponents;
