import * as React from 'react';
import "../astrologer/astrologer.css"
import Slider from "react-slick";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Link, Button } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Rating from '@mui/material/Rating';
import astrologerImg1 from "../../images/astrologer-1.png";
import { NextArrow, PrevArrow } from "./../slick-arrow/SlickCarousalArrow";




export default class AstrologerComponent extends React.Component<any, any>{
    render() {
        const Carouselsettings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 4,
            initialSlide: 0,
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />,
            variableWidth: true,
            swipeToSlide: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <React.Fragment>
                <Box sx={{ width: '100%' }} className='heading mt-16'>
                    <Typography variant="h2" className='heading-title'>Astrologers</Typography>
                    <Link href="/" className='heading-link'>See all</Link>
                </Box>
                <Box sx={{ flexGrow: 1 }} className='astrologer-sections'>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Slider {...Carouselsettings}>
                                <Card className='astrologer-card'>
                                    <CardContent className='astro-card-content'>
                                        <Link href="/">
                                            <img src={astrologerImg1} alt="astrologer" width={80} height={80} />
                                            <div className='align-text'>
                                                <div className='card-body-text'>
                                                    <h5>Kabir Sharma</h5>
                                                    <Rating name="size-small" defaultValue={4} size="small" readOnly className='mb-3' />
                                                    <p>Vedic, Vastu</p>
                                                    <p>English, Hindi</p>
                                                </div>
                                                <div>
                                                    <span className='astro-price'>$2/min</span>
                                                    <Button size="small" variant='outlined' color='warning' className='astro-btn'>Chat Now</Button>
                                                </div>
                                            </div>
                                        </Link>
                                    </CardContent>
                                </Card>
                                <Card className='astrologer-card'>
                                    <CardContent className='astro-card-content'>
                                        <Link href="/">
                                            <img src={astrologerImg1} alt="astrologer" width={80} height={80} />
                                            <div className='align-text'>
                                                <div className='card-body-text'>
                                                    <h5>Lovepreet Singh</h5>
                                                    <Rating name="size-small" defaultValue={4} size="small" readOnly className='mb-3' />
                                                    <p>Vedic, Vastu</p>
                                                    <p>English, Hindi</p>
                                                </div>
                                                <div className="w-52">
                                                    <span className='astro-price'>$2/min</span>
                                                    <Button size="small" variant='outlined' color='warning' className='astro-btn'>Chat Now</Button>
                                                </div>
                                            </div>
                                        </Link>
                                    </CardContent>
                                </Card>
                                <Card className='astrologer-card'>
                                    <CardContent className='astro-card-content'>
                                        <Link href="/">
                                            <img src={astrologerImg1} alt="astrologer" width={80} height={80} />
                                            <div className='align-text'>
                                                <div className='card-body-text'>
                                                    <h5>Kabir Sharma</h5>
                                                    <Rating name="size-small" defaultValue={4} size="small" readOnly className='mb-3' />
                                                    <p>Vedic, Vastu</p>
                                                    <p>English, Hindi</p>
                                                </div>
                                                <div>
                                                    <span className='astro-price'>$2/min</span>
                                                    <Button size="small" variant='outlined' color='warning' className='astro-btn'>Chat Now</Button>
                                                </div>
                                            </div>
                                        </Link>
                                    </CardContent>
                                </Card>
                                <Card className='astrologer-card'>
                                    <CardContent className='astro-card-content'>
                                        <Link href="/">
                                            <img src={astrologerImg1} alt="astrologer" width={80} height={80} />
                                            <div className='align-text'>
                                                <div className='card-body-text'>
                                                    <h5>Kabir Sharma</h5>
                                                    <Rating name="size-small" defaultValue={4} size="small" readOnly className='mb-3' />
                                                    <p>Vedic, Vastu</p>
                                                    <p>English, Hindi</p>
                                                </div>
                                                <div>
                                                    <span className='astro-price'>$2/min</span>
                                                    <Button size="small" variant='outlined' color='warning' className='astro-btn'>Chat Now</Button>
                                                </div>
                                            </div>
                                        </Link>
                                    </CardContent>
                                </Card>
                                <Card className='astrologer-card'>
                                    <CardContent className='astro-card-content'>
                                        <Link href="/">
                                            <img src={astrologerImg1} alt="astrologer" width={80} height={80} />
                                            <div className='align-text'>
                                                <div className='card-body-text'>
                                                    <h5>Kabir Sharma</h5>
                                                    <Rating name="size-small" defaultValue={4} size="small" readOnly className='mb-3' />
                                                    <p>Vedic, Vastu</p>
                                                    <p>English, Hindi</p>
                                                </div>
                                                <div>
                                                    <span className='astro-price'>$2/min</span>
                                                    <Button size="small" variant='outlined' color='warning' className='astro-btn'>Chat Now</Button>
                                                </div>
                                            </div>
                                        </Link>
                                    </CardContent>
                                </Card>
                                <Card className='astrologer-card'>
                                    <CardContent className='astro-card-content'>
                                        <Link href="/">
                                            <img src={astrologerImg1} alt="astrologer" width={80} height={80} />
                                            <div className='align-text'>
                                                <div className='card-body-text'>
                                                    <h5>Kabir Sharma</h5>
                                                    <Rating name="size-small" defaultValue={4} size="small" readOnly className='mb-3' />
                                                    <p>Vedic, Vastu</p>
                                                    <p>English, Hindi</p>
                                                </div>
                                                <div>
                                                    <span className='astro-price'>$2/min</span>
                                                    <Button size="small" variant='outlined' color='warning' className='astro-btn'>Chat Now</Button>
                                                </div>
                                            </div>
                                        </Link>
                                    </CardContent>
                                </Card>
                            </Slider>
                        </Grid>
                    </Grid>
                </Box>
            </React.Fragment>
        )
    }
}