import * as React from 'react';
import "./../blogDetails/blogDetails.css";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Link, Typography } from '@mui/material';
import HorizantalTopBanner from '../appBanners/HorizantalTopBanner';
import SidebarCardComponent from '../sidebarCard/SidebarCardComponent';
import VerticalAppBanner from '../appBanners/VerticalAppBanner';
import blogHeroImg from "./../../images/blog-heroImg.png";
import appBannerImg from "./../../images/app-banner-2-1083x261.svg";
import facebook from '../../icons/facebook.svg';
import twitter from '../../icons/twitter.svg';
import instagram from '../../icons/instagram.svg';
import youTube from '../../icons/youtube.svg';
import AstrologerComponent from '../astrologer/AstrologerComponent';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import blogImg1 from "../../images/blog-1.png";
import blogImg2 from "../../images/blog-2.png";
import blogImg3 from "../../images/blog-3.png";
import blogImg4 from "../../images/blog-4.png";
import blogImg5 from "../../images/blog-5.png";
import blogImg6 from "../../images/blog-6.png";
import blogImg7 from "../../images/blog-7.png";
import blogImg8 from "../../images/blog-8.png";
import VisibilityIcon from '@mui/icons-material/Visibility';

interface BlogDetailsComponentProps {
    title: String;
}

interface BlogDetailsComponentState {
    title: String;
    blogData: Array<BlogDataState>;
    heroImg: any;
    socialIcons: Array<SocialIconsState>;
    synopsis: String;
    bannerAds: any;
    bannerLink: any;
    blogBody: Array<BlogBodyState>;
}

interface BlogDataState {
    thumbImg: any;
    title: String;
    author: String;
    date: any;
    views: number;
}
interface SocialIconsState {
    actionLink: any;
    icons: any;
    title: any;
}
interface BlogBodyState {
    title: String
    text: String;
    subTitle: String;
    linkText: String;
    actionLink: String;
}

class BlogDetailsComponent extends React.Component<BlogDetailsComponentProps, BlogDetailsComponentState> {
    constructor(props: BlogDetailsComponentProps) {
        super(props);
        this.state = {
            title: "Recent Blogs",
            heroImg: blogHeroImg,
            socialIcons: [
                { icons: facebook, title: "facebook", actionLink: "facebook" },
                { icons: twitter, title: "twitter", actionLink: "twitter" },
                { icons: instagram, title: "instagram", actionLink: "instagram" },
                { icons: youTube, title: "youTube", actionLink: "youTube" },
            ],
            synopsis: "Are you curious about which zodiac signs are destined for success? Astrology has long been a fascinating tool for understanding our strengths and weaknesses. In this blog, we’ll explore four zodiac signs that seem to have an inherent connection with success. From ambitious Aries to determined Capricorn, these signs are believed to be destined for greatness. Let’s dive into the intriguing world of astrology and discover if your sign is on the list.",
            bannerAds: appBannerImg,
            bannerLink: "home",
            blogBody: [
                { title: "Aries", text: "Aries, the first sign of the zodiac, is known for its fearless and adventurous nature. Individuals born under this sign are natural leaders, always ready to take the initiative. Their dynamic energy and determination make them unstoppable in pursuing their goals. If you’re an Aries, your ambitious spirit could be the key to unlocking a path to success.", subTitle: "Worried About Your Life Ahead? ", linkText: "Talk to Astrologer ", actionLink: "/" },
                { title: "Leo", text: "Leos are often considered the royalty of the zodiac, ruled by the sun and basking in its warm glow. With their natural charisma and leadership skills, Leos are born to stand out. Their confidence and magnetic personality make them natural-born leaders, excelling in careers that require a strong presence. If you’re a Leo, your regal qualities may be your ticket to success.", subTitle: "Want To Bring Back Your Lost Love? ", linkText: "Chat with an Astrologer ", actionLink: "/" },
                { title: "Scorpio", text: "Scorpios are known for their intensity and determination. Behind their mysterious exterior lies a powerhouse of ambition and resilience. Once a Scorpio sets their sights on a goal, they stop at nothing to achieve it. This unwavering determination often propels them towards success in both personal and professional endeavors. If you’re a Scorpio, your mysterious allure may hold the key to your success.", subTitle: "Also Read:", linkText: "4 Zodiac Signs Who Are Passionate For Their Lover", actionLink: "/" },
                { title: "Capricorn", text: "Capricorns are the epitome of discipline and hard work. Ruled by Saturn, the planet of structure and responsibility, Capricorns approach life with a strategic mindset. Their dedication and strong work ethic enable them to climb the ladder of success steadily. If you’re a Capricorn, your disciplined approach may be the driving force behind your achievements.", subTitle: "", linkText: "", actionLink: "" }
            ],
            blogData: [
                { thumbImg: blogImg1, title: "Lorem Ipsum is simply dummy text of the printing and typesetting industry printing.", author: "Admin", date: "Jul 13, 2023", views: 223 },
                { thumbImg: blogImg4, title: "Lorem Ipsum is simply dummy text of the printing and typesetting industry printing.", author: "Admin", date: "Jul 13, 2023", views: 223 },
                { thumbImg: blogImg3, title: "Lorem Ipsum is simply dummy text of the printing and typesetting industry printing.", author: "Admin", date: "Jul 13, 2023", views: 223 },
                { thumbImg: blogImg2, title: "Lorem Ipsum is simply dummy text of the printing and typesetting industry printing.", author: "Admin", date: "Jul 13, 2023", views: 223 },
                { thumbImg: blogImg5, title: "Lorem Ipsum is simply dummy text of the printing and typesetting industry printing.", author: "Admin", date: "Jul 13, 2023", views: 223 },
                { thumbImg: blogImg6, title: "Lorem Ipsum is simply dummy text of the printing and typesetting industry printing.", author: "Admin", date: "Jul 13, 2023", views: 223 },
                { thumbImg: blogImg7, title: "Lorem Ipsum is simply dummy text of the printing and typesetting industry printing.", author: "Admin", date: "Jul 13, 2023", views: 223 },
                { thumbImg: blogImg8, title: "Lorem Ipsum is simply dummy text of the printing and typesetting industry printing.", author: "Admin", date: "Jul 13, 2023", views: 223 },
            ]
        };
    }
    render() {
        return (
            <React.Fragment>
                <main>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item md={9} xs={12}>
                                <HorizantalTopBanner />
                                <Box className='blog-details-wrap mt-16'>
                                    <Typography variant='h4' className='heading-wtbg'>{this.state.title}</Typography>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} className='blog-details'>
                                            <Typography component="div" className='hero-img'>
                                                <img src={this.state.heroImg} alt="hero" width={896} height={504} />
                                            </Typography>
                                            <div className='blog-social-wrap'>
                                                <ul className='social-icons'>
                                                    {
                                                        this.state.socialIcons.map((socialIcon, index) => {
                                                            return <li key={index}>
                                                                <Link href={socialIcon.actionLink}><img src={socialIcon.icons} width={28} height={28} alt={socialIcon.title} /></Link>
                                                            </li>
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                            <div className='synopsis'>
                                                <Typography variant='body1'>
                                                    {this.state.synopsis}
                                                </Typography>
                                            </div>
                                            <div className='blog-app-banner'>
                                                <Link href={this.state.bannerLink} target="_blank">
                                                    <img src={this.state.bannerAds} alt="banner ads" width={896} height={217} />
                                                </Link>
                                            </div>

                                        </Grid>
                                        {
                                            this.state.blogBody.map((blogBodyData, index) => {
                                                return <Grid item xs={12} className='blog-details-body'>
                                                    <Typography variant='h5' className='body-heading'>
                                                        {blogBodyData.title}
                                                    </Typography>
                                                    <Typography variant='body1'>
                                                        {blogBodyData.text}
                                                    </Typography>
                                                    <h6 className='body-sub-heading'>{blogBodyData.subTitle}<Link href="/">{blogBodyData.linkText}</Link></h6>
                                                </Grid>
                                            })
                                        }
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <SidebarCardComponent />
                                <VerticalAppBanner />
                            </Grid>
                        </Grid>
                    </Box>
                    <AstrologerComponent />
                    <Box className='blog-list-wrap mt-16'>
                        <Typography variant='h4' className='heading-wtbg text-center'>{this.state.title}</Typography>
                        <Grid container spacing={2}>
                            {
                                this.state.blogData.map((blogData, index) => {
                                    return <Grid item md={3} sm={4} xs={12} key={index}>
                                        <Card className='blog-card'>
                                            <CardContent className='blog-card-content'>
                                                <Link href="/blog-details">
                                                    <img src={blogData.thumbImg} alt="blog" width={351} height={201} />
                                                    <div className='blog-cardbody'>
                                                        <h6 className='blog-card-title'>{blogData.title}</h6>
                                                        <div className='date-author-box'>
                                                            <span>{blogData.author}</span>
                                                            <span>{blogData.date}</span>
                                                        </div>
                                                    </div>
                                                    <div className='card-overlay'>
                                                        <VisibilityIcon fontSize='small' /> {blogData.views}
                                                    </div>
                                                </Link>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                })
                            }


                        </Grid>

                    </Box>
                </main>
            </React.Fragment>
        );
    }
}

export default BlogDetailsComponent;