import * as React from 'react';
import "../horoscope/horoscope.css";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Button, Link } from '@mui/material';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import hAries from './../../icons/h-aries.svg';
import hTaurus from './../../icons/h-taurus.svg';
import hGemini from './../../icons/gemini.svg';
import hCancer from './../../icons/cancer.svg';
import hLeo from './../../icons/leo.svg';
import hVirgo from './../../icons/virgo.svg';
import hLibra from './../../icons/libra.svg';
import hScorpio from './../../icons/scorpio.svg';
import hSagittarius from './../../icons/sagittarius.svg';
import hCapricorn from './../../icons/capricorn.svg';
import hAquarius from './../../icons/aquarius.svg';
import hPisces from './../../icons/pisces.svg';

interface HoroscopeTabProps {

}

export interface HoroscopeTabState {
    index: number;
    value: any;
    horoscopeName: string;
    dayTime: string;
    horoscopeDesc: string;
    readMore: boolean;

}

export default class HoroscopeComponent extends React.Component<HoroscopeTabProps, HoroscopeTabState>{
    constructor(props: HoroscopeTabProps) {
        super(props);
        this.state = {
            index: 0,
            value: 0,
            horoscopeName: "Aries Daily Horoscope",
            readMore: false,
            dayTime: "Friday, November, 2023",
            horoscopeDesc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. when an unknown printer took a galley of type and scrambled it to make a type specimen book simply dummy text of the printing and typesetting industry. when an unknown printer took a galley of type and scrambled it to make a type specimen boo. Lorem Ipsum is simply dummy text of the printing and typesetting industry.",


        }
    }
    handleChange = (e: React.ChangeEvent<any>) => {
        this.setState({ value: e.target.value });
    }
    handleClick = () => {
        this.setState({
            horoscopeDesc: this.state.horoscopeDesc
        })
    }
    handleReadMore = () => {
        this.setState({
            readMore: !this.state.readMore
        })
    }
    render() {
        return (
            <React.Fragment>
                <Box sx={{ width: '100%' }} className='heading mt-16'>
                    <Typography variant="h2" className='heading-title'>Horoscope</Typography>
                    <Link href="/horoscope-details" className='heading-link'>See all</Link>
                </Box>
                <Box sx={{ width: '100%' }} className="horoscope-tab">
                    <Tabs defaultActiveKey="daily" id="horoscopeTab" className="mb-0" fill>
                        <Tab eventKey="daily" title="Daily">
                            <ul className='horoscope-lists'>
                                <li className='active'>
                                    <Button onClick={this.handleClick}><img src={hAries} alt='' /></Button>
                                </li>
                                <li>
                                    <Button onClick={this.handleClick}><img src={hTaurus} alt='' /></Button>
                                </li>
                                <li>
                                    <Button onClick={this.handleClick}><img src={hGemini} alt='' /></Button>
                                </li>
                                <li>
                                    <Button onClick={this.handleClick}><img src={hCancer} alt='' /></Button>
                                </li>
                                <li>
                                    <Button onClick={this.handleClick}><img src={hLeo} alt='' /></Button>
                                </li>
                                <li>
                                    <Button onClick={this.handleClick}><img src={hVirgo} alt='' /></Button>
                                </li>
                                <li>
                                    <Button onClick={this.handleClick}><img src={hLibra} alt='' /></Button>
                                </li>
                                <li>
                                    <Button onClick={this.handleClick}><img src={hScorpio} alt='' /></Button>
                                </li>
                                <li>
                                    <Button onClick={this.handleClick}><img src={hSagittarius} alt='' /></Button>
                                </li>
                                <li>
                                    <Button onClick={this.handleClick}><img src={hCapricorn} alt='' /></Button>
                                </li>
                                <li>
                                    <Button onClick={this.handleClick}><img src={hAquarius} alt='' /></Button>
                                </li>
                                <li>
                                    <Button onClick={this.handleClick}><img src={hPisces} alt='' /></Button>
                                </li>
                            </ul>
                            <div className='horoscope-contents'>
                                <div className='htitle-date'>
                                    <h2>{this.state.horoscopeName}</h2>
                                    <h6>{this.state.dayTime}</h6>
                                </div>
                                <p className='horoscope-p'>
                                    {this.state.readMore ? this.state.horoscopeDesc : `${this.state.horoscopeDesc.substring(0, 315).concat("...")}`}
                                    {<Button onClick={this.handleReadMore}>{this.state.readMore ? "Read Less" : "Read More"}</Button>}
                                </p>
                            </div>
                        </Tab>
                        <Tab eventKey="weekly" title="Weekly">
                            Tab content for Profile
                        </Tab>
                        <Tab eventKey="monthly" title="Monthly">
                            Tab content for Loooonger Tab
                        </Tab>
                        <Tab eventKey="yearly" title="Yearly">
                            Tab content for Contact
                        </Tab>
                    </Tabs>
                </Box>
            </React.Fragment>
        )
    }
}