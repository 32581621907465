import * as React from 'react';
import "./../horoscope/horoscopeList.css";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Button, Link, Typography } from '@mui/material';
import HorizantalTopBanner from '../appBanners/HorizantalTopBanner';
import SidebarCardComponent from '../sidebarCard/SidebarCardComponent';
import VerticalAppBanner from '../appBanners/VerticalAppBanner';
import astrologyImg from "../../images/astrology.png";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import hAries from './../../icons/h-aries.svg';
import hTaurus from './../../icons/h-taurus.svg';
import hGemini from './../../icons/gemini.svg';
import hCancer from './../../icons/cancer.svg';
import ListGroup from 'react-bootstrap/ListGroup';


interface HoroscopeListComponentProps {

}

interface HoroscopeListComponentState {
    title: String;
    desc: Array<HoroscopeDescState>;
    bodyDesc: Array<horoBodyDescState>;
    readMore: boolean;
    cardContent: Array<HoroscopeCard>


}
interface HoroscopeDescState {
    title: String;
    horoBody: Array<horoBodyDescState>;
}
interface horoBodyDescState {
    descText: String;
}

interface HoroscopeCard {
    iconImg: any;
    imgAlt: any;
    heading: String;
    subHeading: String;
    desc: String;
    readMoreBtn: Boolean;
    list: Array<HoroscopeCardList>

}
interface HoroscopeCardList {
    items: String;
    actionLink: any;
}

class HoroscopeListComponent extends React.Component<HoroscopeListComponentProps, HoroscopeListComponentState> {
    constructor(props: HoroscopeListComponentProps) {
        super(props);
        this.state = {

            readMore: false,
            title: "what is horoscope ?",
            bodyDesc: [
                { descText: "Horoscope is a predictive astrological chart that offers insights into a person's character, life events, and potential future occurrences based on the positions of celestial bodies at the time of their birth." },
                { descText: "Looking for today's horoscope? Or yesterday's? Well, on AstroConsult we have covered everything right from Daily horoscope to weekly to monthly horoscope. What is the use of one's Daily horoscope you may ask? Well, as per our astrologers, the daily horoscope is one of the ways that you can use to plan out your day. As per astrology, our day to day life is influenced by the movements of planets, as they constantly shift their positions from one sign to another. The movement can bring both positive and negative influences in one's life, and you knowing such things in advance helps you in safeguarding yourself from the uncertainties." },
                { descText: "So reading Today's horoscope is like a healthy habit one can consider adopting as it shall help in changing the outcomes of your life. The daily horoscope on AstroConsult is prepared by expert astrologers and thus is very insightful. The daily horoscope not only tells you what's coming for you in the future but also allows you remedies that you can adopt to tackle anything negative. The Today's horoscope is one of the ways that can help you in steering your life in the right direction. So make sure you give it a read." },
            ],
            desc: [
                {
                    title: "benefits of horoscope ",
                    horoBody: [
                        { descText: "Horoscopes offer a range of potential benefits to individuals seeking guidance or introspection. For many, reading their horoscope serves as a daily or periodic ritual that prompts self-reflection. It can provide a sense of direction or reassurance, offering advice or predictions that individuals might interpret as relevant to their current situations. This perceived guidance can sometimes offer comfort in decision-making or navigating life's uncertainties. Additionally, horoscopes can create a sense of community among those who share the same zodiac sign, fostering connections and discussions. As a form of entertainment, they offer a light-hearted glimpse into potential future events. Furthermore, checking one's horoscope can act as a mindful pause, encouraging individuals to consider their thoughts and actions in relation to the predictions. Despite these perceived benefits, it's important to approach horoscopes with a critical mindset, acknowledging their lack of empirical evidence and scientific validity. Ultimately, their impact tends to be subjective, varying greatly among individuals based on personal beliefs and experiences." }

                    ]
                },
                {
                    title: "Importance Of Today Horoscope",
                    horoBody: [
                        { descText: "A horoscope is an astrological chart that shows you the position of the sun, moon, planets, and the astrological aspect of an individual. Astrology is a simple way of forecasting your future. It not only helps you prepare for various events, but it also tells you about different prospects such as career, love, marriage, relationships, etc. In short, by reading today's horoscope, you can be acclaimed of all the incidents that are going to occur in future. The term 'horoscope' has been taken from Greek words hõra and scopos, meaning 'time' and 'observer'. Zodiac signs daily horoscope today say that there are also different names used for horoscopes including astrological chart, natal chart, celestial map, star chart, radical chart, Astro chart, radix chart wheel, cosmogram, vitasphere, and chart. Reading today's horoscope gives you an accurate prediction of your actions and suggests different ways to avoid any mishappening or unforeseeable situation." },

                    ]
                },

            ],
            cardContent: [
                {
                    iconImg: hAries,
                    imgAlt: "hAries",
                    heading: "Daily Aries Horoscope",
                    subHeading: "Friday, November 24, 2023",
                    desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. when an unknown printer took a galley of type and scrambled it to make a type specimen book. when an unknown printer took a galley of type and scrambled it to make a type specimen book",
                    readMoreBtn: false,
                    list: [
                        { items: "Weekly Gemini Horoscope", actionLink: "/weekly" },
                        { items: "Monthly Gemini Horoscope", actionLink: "/monthly" },
                        { items: "Yearly Gemini Horoscope", actionLink: "/yearly" },
                    ]
                },
                {
                    iconImg: hTaurus,
                    imgAlt: "hTaurus",
                    heading: "Daily Aries Horoscope",
                    subHeading: "Friday, November 24, 2023",
                    desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. when an unknown printer took a galley of type and scrambled it to make a type specimen book. when an unknown printer took a galley of type and scrambled it to make a type specimen book",
                    readMoreBtn: false,
                    list: [
                        { items: "Weekly Gemini Horoscope", actionLink: "/weekly" },
                        { items: "Monthly Gemini Horoscope", actionLink: "/monthly" },
                        { items: "Yearly Gemini Horoscope", actionLink: "/yearly" },
                    ]
                },
                {
                    iconImg: hGemini,
                    imgAlt: "hGemini",
                    heading: "Daily Aries Horoscope",
                    subHeading: "Friday, November 24, 2023",
                    desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. when an unknown printer took a galley of type and scrambled it to make a type specimen book. when an unknown printer took a galley of type and scrambled it to make a type specimen book",
                    readMoreBtn: false,
                    list: [
                        { items: "Weekly Gemini Horoscope", actionLink: "/weekly" },
                        { items: "Monthly Gemini Horoscope", actionLink: "/monthly" },
                        { items: "Yearly Gemini Horoscope", actionLink: "/yearly" },
                    ]
                },
                {
                    iconImg: hCancer,
                    imgAlt: "hCancer",
                    heading: "Daily Aries Horoscope",
                    subHeading: "Friday, November 24, 2023",
                    desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. when an unknown printer took a galley of type and scrambled it to make a type specimen book. when an unknown printer took a galley of type and scrambled it to make a type specimen book",
                    readMoreBtn: false,
                    list: [
                        { items: "Weekly Gemini Horoscope", actionLink: "/weekly" },
                        { items: "Monthly Gemini Horoscope", actionLink: "/monthly" },
                        { items: "Yearly Gemini Horoscope", actionLink: "/yearly" },
                    ]
                },

            ]

        };
    }
    handleReadMore = () => {
        this.setState({
            readMore: !this.state.readMore
        })
    }
    render() {
        return (
            <React.Fragment>
                <main>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item md={9} xs={12}>
                                <HorizantalTopBanner />
                                <Box sx={{ flexGrow: 1 }} className='astrology-section'>
                                    <Grid container spacing={2} className='mb-45'>
                                        <Grid item md={7}>
                                            <Typography variant="h4" className='heading-title'>{this.state.title}</Typography>
                                            {
                                                this.state.bodyDesc.map((bodyDescT) => {
                                                    return <Typography variant='body1'>
                                                        {bodyDescT.descText}
                                                    </Typography>
                                                })
                                            }
                                        </Grid>
                                        <Grid item md={5}>
                                            <div className='d-flex justify-content-center align-items-center h-100'>
                                                <img src={astrologyImg} alt="astrology" width={456} height={456} style={{ maxWidth: "100%", height: "auto" }} />
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item md={12}>
                                            {
                                                this.state.desc.map((horoItms) => {
                                                    return <div className='mb-45'>
                                                        <Typography variant="h4" className='heading-title'>{horoItms.title}</Typography>
                                                        <div>
                                                            {
                                                                horoItms.horoBody.map((horoDesc) => {
                                                                    return <Typography variant='body1'>
                                                                        {horoDesc.descText}

                                                                    </Typography>
                                                                })
                                                            }
                                                            {/* <p className='horoscope-p'>
                                                                {this.state.readMore ? this.state.horoscopeDesc : `${this.state.horoscopeDesc.substring(0, 315).concat("...")}`}
                                                                
                                                            </p> */}
                                                        </div>
                                                    </div>
                                                })

                                            }

                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <SidebarCardComponent />
                                <VerticalAppBanner />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{ width: '100%' }} className='heading mt-16'>
                        <Typography variant="h2" className='heading-title'>Daily Horoscope</Typography>
                        <Link href="/horoscope-details" className='heading-link'>See all</Link>
                    </Box>
                    <Box sx={{ width: '100%' }} className="horoscope-list">
                        <Grid container spacing={2}>
                            {
                                this.state.cardContent.map((cardItem) => {
                                    return <Grid item md={4} sm={6}>
                                        <Card className='horo-list-card'>
                                            <CardContent>
                                                <div className='card-content-inner'>
                                                    <div className='horo-icon-1x1'><img src={cardItem.iconImg} alt={cardItem.imgAlt} /></div>
                                                    <Typography component="h5">
                                                        {cardItem.heading}
                                                    </Typography>
                                                    <Typography component="h6" gutterBottom>
                                                        {cardItem.subHeading}
                                                    </Typography>
                                                    <p className='horoscope-p'>
                                                        {/* {cardItem.desc} */}
                                                        {this.state.readMore ? cardItem.desc : `${cardItem.desc.substring(0, 115).concat("...")}`}
                                                        {<Button onClick={this.handleReadMore}>{this.state.readMore ? "Read Less" : "Read More"}</Button>}
                                                    </p>

                                                </div>
                                                <ListGroup className='horo-card-lists'>
                                                    {
                                                        cardItem.list.map((listItem) => {
                                                            return <ListGroup.Item action href={`${listItem.actionLink}-horoscope-details`} key={listItem.actionLink}>
                                                                {listItem.items}
                                                            </ListGroup.Item>
                                                        })
                                                    }
                                                </ListGroup>
                                            </CardContent>

                                        </Card>
                                    </Grid>
                                })
                            }
                        </Grid>
                    </Box>

                </main>
            </React.Fragment>
        );
    }
}

export default HoroscopeListComponent;