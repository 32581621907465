import * as React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomeComponent from '../home/HomeComponent';
import HoroscopeListComponent from '../horoscope/HoroscopeListComponent';
import HeaderComponent from '../header/HeaderComponents';
import FooterComponent from '../footer/FooterComponent';
import HoroscopeDetailsComponent from '../horoscopeDetails/HoroscopeDetailsComponent';
import FreeKundaliListing from '../freeKundali-list/FreeKundaliListing';
import KundaliDetailsComponent from '../freeKundaliDetails/KundaliDetails';
import KundaliMatchListing from '../kundali-match-listing/KundaliMatchListing';
import KundaliMatchReport from '../kundaliMatch-report/KundaliMatchReport';
import PanchangComponent from '../panchang/PanchangComponent';
import BlogListComponent from '../blogs/BlogListComponent';
import BlogDetailsComponent from '../blogDetails/BlogDetailsComponent';
import AstrologyVideoList from '../astrologyVideo-list/AstrologyVideoList';
import FestivalLists from '../festival-list/FestivalLists';
import FestivalDetails from '../festival-details/FestivalDetails';
import MarriageCompatibility from '../marriage-compatibility/MarriageCompatibility';
import MarriageCompatibilityDetails from '../marriage-compatibility-details/MarriageCompatibilityDetails';
import AboutUs from '../static-pages/AboutUs';


interface RoutesProps {

}

interface RoutesState {

}

class RoutesComponent extends React.Component<RoutesProps, RoutesState> {
    constructor(props: RoutesProps) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <React.Fragment>
                <HeaderComponent />
                <Router>
                    <Routes>
                        <Route path="/" element={<HomeComponent />} />
                        <Route path="/home" element={<HomeComponent />} />
                        <Route path="/horoscope" element={<HoroscopeListComponent />} />
                        <Route path="/horoscope-details" element={<HoroscopeDetailsComponent />} />
                        <Route path="/weekly-horoscope-details" element={<HoroscopeDetailsComponent />} />
                        <Route path="/monthly-horoscope-details" element={<HoroscopeDetailsComponent />} />
                        <Route path="/yearly-horoscope-details" element={<HoroscopeDetailsComponent />} />
                        <Route path="/free-kundali" element={<FreeKundaliListing />} />
                        <Route path="/free-kundali-details" element={<KundaliDetailsComponent />} />
                        <Route path="/kundali-match" element={<KundaliMatchListing />} />
                        <Route path="/kundali-match-reports" element={<KundaliMatchReport />} />
                        <Route path="/panchang" element={<PanchangComponent />} />
                        <Route path="/blogs" element={<BlogListComponent />} />
                        <Route path="/blog-details" element={<BlogDetailsComponent title="4 Zodiac Signs That Are Made For Success" />} />
                        <Route path="/astrology-video" element={<AstrologyVideoList />} />
                        <Route path="/festival-lists" element={<FestivalLists />} />
                        <Route path="/festival-details" element={<FestivalDetails />} />
                        <Route path="/marriage-compatibility" element={<MarriageCompatibility />} />
                        <Route path="/marriage-compatibility-details" element={<MarriageCompatibilityDetails />} />
                        <Route path="/about-us" element={<AboutUs />} />
                    </Routes>
                </Router>
                <FooterComponent />
            </React.Fragment>
        );
    }
}

export default RoutesComponent;