import * as React from 'react';
import "../todayDeals/todayDeals.css";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Link } from '@mui/material';
import todayDeals1 from '../../images/today-deals-1.png';
import todayDeals2 from '../../images/today-deals-2.png';
import todayDeals3 from '../../images/today-deals-3.png';
import todayDeals4 from '../../images/today-deals-4.png';



export default class TodayDealsComponent extends React.Component<any, any>{
    render() {
        return <div className='mb-3'>
            <Box sx={{ width: '100%' }} className='heading mt-16'>
                <Typography variant="h2" className='heading-title'>Today's deals</Typography>
                <Link href="/" className='heading-link'>See all</Link>
            </Box>
            <Box sx={{ flexGrow: 1 }} className='today-deals'>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <ul className='today-deals-card'>
                            <li>
                                <img src={todayDeals1} alt="today deals" width={143} height={125} />
                                <h4 className='deals-card-heading'>Jamuniya pendent with chain</h4>
                                <Link className='theme-btn card-btn'>Buy Now</Link>
                            </li>
                            <li>
                                <img src={todayDeals2} alt="today deals" width={143} height={125} />
                                <h4 className='deals-card-heading'>Jamuniya pendent with chain</h4>
                                <Link className='theme-btn card-btn'>Buy Now</Link>
                            </li>
                            <li>
                                <img src={todayDeals3} alt="today deals" width={143} height={125} />
                                <h4 className='deals-card-heading'>Jamuniya pendent with chain</h4>
                                <Link className='theme-btn card-btn'>Buy Now</Link>
                            </li>
                            <li>
                                <img src={todayDeals4} alt="today deals" width={143} height={125} />
                                <h4 className='deals-card-heading'>Jamuniya pendent with chain</h4>
                                <Link className='theme-btn card-btn'>Buy Now</Link>
                            </li>
                        </ul>
                    </Grid>
                </Grid>
            </Box>
        </div>
    }
}