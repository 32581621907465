import * as React from 'react';
import "../freeKundali/freeKundali.css";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import { Button } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';


const locations = [
    { place: "Mumbai Maharashtra" },
    { place: "New Delhi, Delhi, India" },
    { place: "Delhi, Indien, Nationales Hauptstadtterritorium Delhi" },
    { place: "Delhi, Nationales Hauptstadtterritorium Delhi, Indien" },
    { place: "Delhi, Louisiana, United States" },
    { place: "Delhi Hills, Ohio, United States" },
]

interface KundaliMatchingProps {

}
interface KundaliMatchingState {
    name: String;
    date: String;
    time: String;
    birthPlace: String;
    partnerName: String;
    partnerDate: String;
    partnerTime: String;
    partnerBirthPlace: String;
}


export default class KundaliMatchingComponent extends React.Component<KundaliMatchingProps, KundaliMatchingState>{
    constructor(props: KundaliMatchingProps) {
        super(props);
        this.state = {
            name: "",
            date: "",
            time: "",
            birthPlace: "",
            partnerName: "",
            partnerDate: "",
            partnerTime: "",
            partnerBirthPlace: "",

        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = (e: any) => {
        e.preventDefault()
        this.setState({
            [e.target.name]: e.target.value
        } as KundaliMatchingProps as KundaliMatchingState)
    }

    render() {
        return (
            <React.Fragment>
                <Box sx={{ flexGrow: 1 }} className='kundali-bgbox'>
                    <Typography variant="h3" className='heading-2 text-center p-3'>Kundali Matching</Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <div className='kundali-forms'>
                                <InputLabel htmlFor="name">Enter Birth Details</InputLabel>
                                <TextField type='text' fullWidth size='small' placeholder='Enter Name' id="name" name='name' value={this.state.name} onChange={this.handleChange} />
                                <TextField type='date' fullWidth size='small' placeholder='dd/mm/yy' id="date" name='date' value={this.state.date} onChange={this.handleChange} />
                                <TextField type='time' fullWidth size='small' placeholder='hh/mm/ss' id="time" name='time' value={this.state.time} onChange={this.handleChange} />
                                <Autocomplete fullWidth id="birthPlace" size='small'
                                    options={locations.map((option) => option.place)}
                                    renderInput={(params) => (
                                        <TextField {...params} placeholder='Enter Your Birth Place' name='birthPlace' />
                                    )}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <div className='kundali-forms'>
                                <InputLabel htmlFor="name">Enter Your Partner Details</InputLabel>
                                <TextField type='text' fullWidth size='small' placeholder='Enter Name' id="partnerName" name='partnerName' value={this.state.partnerName} onChange={this.handleChange} />
                                <TextField type='date' fullWidth size='small' placeholder='dd/mm/yy' id="partnerDate" name='partnerDate' value={this.state.partnerDate} onChange={this.handleChange} />
                                <TextField type='time' fullWidth size='small' placeholder='hh/mm/ss' id="partnerTime" name='partnerTime' value={this.state.partnerTime} onChange={this.handleChange} />
                                <Autocomplete fullWidth id="partnerBirthPlace" size='small'
                                    options={locations.map((option) => option.place)}
                                    renderInput={(params) => (
                                        <TextField {...params} placeholder='Enter Your Birth Place' name='partnerBirthPlace' />
                                    )}
                                />

                            </div>
                        </Grid>
                    </Grid>
                    <div className='d-flex justify-content-center'>
                        <Button type='submit' className='theme-btn btn-submit' >Match</Button>
                    </div>
                </Box>
            </React.Fragment>
        )
    }
}