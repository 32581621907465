import * as React from 'react';
import "../home/homeComponents.css";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Button, Link, Typography } from '@mui/material';
import TodayDealsComponent from '../todayDeals/TodayDealsComponent';
import FreeKundaliComponent from '../freeKundali/FreeKundaliComponent';
import KundaliMatchingComponent from '../kundaliMatching/KundaliMatchingComponent';
import HoroscopeComponent from '../horoscope/HoroscopeComponent';
import AstrologerComponent from '../astrologer/AstrologerComponent';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import ListGroup from 'react-bootstrap/ListGroup';
import AstrologyComponent from '../astrology/AstrologyComponent';
import BlogComponent from '../blogs/BlogComponent';
import AstrologyCarousal from '../astrologyVideo/AstrologyCarousalComponent';
import HorizantalTopBanner from '../appBanners/HorizantalTopBanner';
import VerticalAppBanner from '../appBanners/VerticalAppBanner';
import SidebarCardComponent from '../sidebarCard/SidebarCardComponent';


export default class HomeComponent extends React.Component<HomeProps, HomeState> {

    constructor(props: HomeProps) {
        super(props);
        this.state = { id: "", authenticated: false, boxWidth: 21, values: "1", };
    }

    handleChange = (e: React.SyntheticEvent, newValue: string) => {
        this.setState({
            values: newValue
        })
    }
    render() {
        return <main>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item md={9} xs={12}>
                        <HorizantalTopBanner />
                        <TodayDealsComponent />

                        <Grid container spacing={2} >
                            <Grid item md={5} xs={12}>
                                <FreeKundaliComponent />
                            </Grid>
                            <Grid item md={7} xs={12}>
                                <KundaliMatchingComponent />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <SidebarCardComponent />
                        <VerticalAppBanner />
                    </Grid>
                </Grid>
            </Box>
            <HoroscopeComponent />
            <AstrologerComponent />
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item md={6} sm={6}>
                        <Box sx={{ width: '100%' }} className='heading mt-16'>
                            <Typography variant="h2" className='heading-title'>Festivals</Typography>
                            <Link href="/festival-lists" className='heading-link'>See all</Link>
                        </Box>
                        <Box sx={{ width: '100%' }} className="festival-tab">
                            <TabContext value={this.state.values}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider', }} className='festival-tablist'>
                                    <TabList onChange={this.handleChange} aria-label="festival">
                                        <Tab label="Festival 2024" value="1" />
                                        <Tab label="Holiday 2024" value="2" />
                                        <Tab label="Calendar 2024" value="3" />
                                    </TabList>
                                </Box>
                                <TabPanel value="1" className='festival-tabpanel'>
                                    <ListGroup variant="flush">
                                        <ListGroup.Item>Makarsankrati, Utrayan, Pongal</ListGroup.Item>
                                        <ListGroup.Item>Holika Dahan</ListGroup.Item>
                                        <ListGroup.Item>Rakshabandhan</ListGroup.Item>
                                        <ListGroup.Item>Krishna Janmashtami</ListGroup.Item>
                                        <ListGroup.Item>Ganesh Chaturthi</ListGroup.Item>
                                        <ListGroup.Item>Aries Daily's Horoscope</ListGroup.Item>
                                        <ListGroup.Item>Choti Diwali</ListGroup.Item>
                                        <ListGroup.Item>Eid Ul Fitra</ListGroup.Item>
                                        <ListGroup.Item>Krishna Janmashtami</ListGroup.Item>
                                    </ListGroup>
                                </TabPanel>
                                <TabPanel value="2" className='festival-tabpanel'>
                                    <ListGroup variant="flush">
                                        <ListGroup.Item>New Year's Day 2024</ListGroup.Item>
                                        <ListGroup.Item>Republic Day 2024</ListGroup.Item>
                                        <ListGroup.Item>Guru Ravidas Jayanti 2024</ListGroup.Item>
                                        <ListGroup.Item>Holika Dahan 2024</ListGroup.Item>
                                        <ListGroup.Item>Holi 2024</ListGroup.Item>
                                        <ListGroup.Item>Ram Navami 2024</ListGroup.Item>
                                        <ListGroup.Item>Choti Diwali</ListGroup.Item>
                                        <ListGroup.Item>Eid Ul Fitra</ListGroup.Item>
                                        <ListGroup.Item>Krishna Janmashtami</ListGroup.Item>
                                    </ListGroup>
                                </TabPanel>
                                <TabPanel value="3" className='festival-tabpanel'>
                                    <ListGroup variant="flush">
                                        <ListGroup.Item>Indian Calendar 2024</ListGroup.Item>
                                        <ListGroup.Item>US Calendar 2024</ListGroup.Item>
                                        <ListGroup.Item>UK Calendar 2024</ListGroup.Item>
                                        <ListGroup.Item>Canada Calendar 2024</ListGroup.Item>
                                        <ListGroup.Item>Canada Calendar 2024</ListGroup.Item>
                                    </ListGroup>
                                </TabPanel>
                            </TabContext>
                        </Box>
                    </Grid>
                    <Grid item md={6} sm={6}>
                        <Box sx={{ width: '100%' }} className='heading mt-16'>
                            <Typography variant="h2" className='heading-title'>Panchang</Typography>
                            <Link href="/" className='heading-link'>See all</Link>
                        </Box>
                        <Box sx={{ width: '100%' }} className="home-panchnag">
                            <h6 className='p-3 mb-0 border-bottom'><strong>New Delhi, India (24 November, 2024)</strong></h6>
                            <ListGroup variant="flush">
                                <ListGroup.Item>
                                    <ul>
                                        <li><strong>Tithi :</strong> Shukla <Link href="/">Dwadashi</Link> upto 19:08:51</li>
                                        <li><strong>Month Amanta :</strong> Shukla <Link href="/">Dwadashi</Link> upto 19:08:51</li>
                                        <li><strong>Month Purnimanta :</strong> Shukla <Link href="/">Dwadashi</Link> upto 19:08:51</li>
                                        <li><strong>Day :</strong> Shukla <Link href="/">Dwadashi</Link> upto 19:08:51</li>
                                    </ul>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <ul>
                                        <li><strong>Nakshatra :</strong> <Link href="/">Revati</Link> next Ashwini upto 16:01:30</li>
                                        <li><strong>Yoga :</strong> Siddhi upto 09:04:26, <Link href="/">Vyatipata</Link> upto 30:23:01</li>
                                        <li><strong>Karan :</strong> <Link href="/">Bhav</Link> upto 08:05:27, Baalav upto 19:08:51</li>
                                    </ul>
                                </ListGroup.Item>
                            </ListGroup>
                            <div className='d-flex justify-content-center mb-4'>
                                <Button className='theme-btn today-panchang'>Today Panchang</Button>
                            </div>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <AstrologyComponent />
            <BlogComponent />
            <AstrologyCarousal />
        </main>

    }

    componentDidMount() {
        this.setState({ boxWidth: 45 });
    }
}

export interface HomeProps {

}

export interface HomeState {
    id: string;
    authenticated: boolean;
    boxWidth: number;
    values: string;

}
