import * as React from 'react';
import "./../kundali-match-listing/kundaliMatchListing.css"
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import HorizantalTopBanner from '../appBanners/HorizantalTopBanner';
import SidebarCardComponent from '../sidebarCard/SidebarCardComponent';
import VerticalAppBanner from '../appBanners/VerticalAppBanner';
import kundaliImg from "./../../images/kundali-match.png";
import KundaliMatchingComponent from '../kundaliMatching/KundaliMatchingComponent';
import AstrologerComponent from '../astrologer/AstrologerComponent';

interface KundaliMatchListingProps {

}

interface KundaliMatchListingState {
    title: String
    textData: Array<KundaliDescState>;
    createKundaliTitle: String;
    createKundaliDesc: Array<KundaliDescState>;
    kundaliMatchData: Array<KundaliMatchDetailsState>;
    value: any;
}

interface KundaliDescState {
    descText: String;
}

interface KundaliMatchDetailsState {
    id: any;
    title: String;
    kundaliMatchP: Array<KundaliDescState>;
    aboutLists: Array<KundaliAboutListState>;
}
interface KundaliAboutListState {
    id: any;
    listItems: String;
}
class KundaliMatchListing extends React.Component<KundaliMatchListingProps, KundaliMatchListingState> {
    constructor(props: KundaliMatchListingProps) {
        super(props);
        this.state = {
            title: "Kundli Matching",
            value: "",
            textData: [
                { descText: "Kundali matching, also known as Gun matching or Horoscope matching, is a Vedic astrology practice that matches the horoscopes of a bride and groom to determine if they are compatible for marriage. It is a key factor in Hindu marriages, particularly in India, where arranged marriages are common." },
                { descText: "The main task of Kundali matching is ashta-koota matching, which considers 36 points between the two horoscopes. The total number of points is made up of 36 Gunas, which are Nadi (8 points), Bhakoot (7 points), Gana (6 points), Maitri (5 points), Yoni (4 points), Tara (3 points), Vasya (2 points), and Varna (1 point). For a marriage to be approved, at least 18 of the 36 Gunas must match." }
            ],
            createKundaliTitle: "What is Kundli maching?",
            createKundaliDesc: [
                { descText: "In Vedic Astrology, the concept of Kundli Matching or Horoscope Matching is very eminent. Marriage is the sacred bond between two separate entities, bringing them together for a long and healthy marital life. There are different names for matchmaking viz, Kundali Milan, Guna Milan, Horoscope Matching and Compatibility, Lagna Melapak etc. The factors which are considered at the time of marriage are :" },
                { descText: "This free Janam Kundali by date of birth and time is effective only when you have exact information. Usually, people make mistake by putting wrong birth time, which eventually gives them the incorrect Kundali with absolutely irrelevant predictions. Check your Kundli chart now and prepare yourself for the upcoming adventures. Online Janam Kundli is easy to make. All you need is to put the right information and click on the submit button. Kundli prediction has its roots back from the Vedic times. We have made sure that our free Kundali chart does every justice to the Vedic tradition." },
                { descText: "AstroSage's free Kundli software provides more than 50 pages report, which covers almost all the aspects of your life. Kundli download is also not very difficult. Just find the download pdf button in your left options after making your detailed Kundali and click on it.If you manage to find a good website, checking Janam Kundli online is always a good idea. After all, astrology is like mathematics, and computer has lesser chances of making mathematical errors. We have various other free services that you may either explore on our website of mobile app. Free online Kundali software offers answers to the general queries, that is why we have got a panel of astrologers who answer your personalized question as a paid service." },
                { descText: "Online Kundli in Hindi has been most popular among all the languages we offer. Hence, we added a lot of content in Hindi language as per the requirement of our Hindi speaking users. Kundali making in Hindi is very simple. This free Janam Kundli in Hindi is no less from any other language. So, get your free Janam Kundali in Hindi now." },
            ],
            kundaliMatchData: [
                {
                    id: 1, title: "",
                    kundaliMatchP: [
                        { descText: "In Vedic Astrology, the concept of Kundli Matching or Horoscope Matching is very eminent. Marriage is the sacred bond between two separate entities, bringing them together for a long and healthy marital life. There are different names for matchmaking viz, Kundali Milan, Guna Milan, Horoscope Matching and Compatibility, Lagna Melapak etc. The factors which are considered at the time of marriage are :" },
                    ],
                    aboutLists: [
                        { id: 1, listItems: "Guna Milan" },
                        { id: 2, listItems: "Manglik Dosha" },
                        { id: 3, listItems: "Strength of Navamsa Chart" },
                    ]
                },
                {
                    id: 2, title: "Guna Milan",
                    kundaliMatchP: [
                        { descText: "In India, Janam Kundli (also called as Birth Chart or Natal Chart) is taken into consideration for Kundli Matching. Guna Milan is based on the position of Moon in the Natal Charts of bride and groom. In North India, there is a process of Guna Milan, called as, 'Ashtakoot Milan', which signifies the eight aspects of Gunas. 'Ashta' means 'Eight' and 'Koota' means 'Aspects'. The eight Kootas are :" },
                    ],
                    aboutLists: [
                        { id: 1, listItems: "Varna/Varan/Jaati : It shows spiritual compatibility of boy and girl along with their ego levels. It is divided into 4 categories, such as Brahmins (Highest), Kshatriya, Vaishya, Shudra (Lowest)." },
                        { id: 2, listItems: "Vasya/Vashya : It shows mutual attraction, control in marriage and also calculates the power equation in between married couples. A person is classified into 5 types, namely Manav/Nara (human), Vanchar (wild animals such as lion), Chatushpad (small animals such as deer), Jalchar (sea animals), Keeta/Keet (insects)." },
                        { id: 3, listItems: "Tara/Dina : It is related to birth star compatibility and destiny. There are 27 birth stars (Nakshatra)." },
                        { id: 4, listItems: "Yoni : It measures the intimacy level, sexual compatibility and mutual love between the couple. Yoni Koot is classified into 14 animals, which are Horse, Elephant, Sheep, Snake, Dog, Cat, Rat, Cow, Buffalo, Tiger, Hare/Deer, Monkey, Lion, Mongoose." },
                        { id: 5, listItems: "Graha Maitri/Rasyadipati : It shows mental compatibility, affection and natural friendship. It also represents the moon sign compatibility between couples." },
                        { id: 6, listItems: "Gana : It is related to behaviour and temperament. Birth stars (Nakshatras) are divided into three categories- Deva (God, indicating Satwa Guna), Manava (Human, indicating Rajo Guna) and Rakshasa (Demon, indicating Tamo Guna)." },
                        { id: 7, listItems: "Rashi or Bhakoot : It relates to the emotional compatibility and love between partners. The position of planets in boy's birth chart is compared with the girl's birth chart. If the boy's moon is placed in 2nd, 3rd, 4th, 5th, 6th house from girl's moon, then it is considered bad or inauspicious, whereas 7th and 12th houses are considered good. In case of female, If natal chart moon is placed in 2nd, 3rd, 4th, 5th and 6th houses from man's chart, then it will be auspicious and inauspicious if placed 12th from man's chart." },
                        { id: 8, listItems: "Nadi : It is related to health and genes. Stars (Nakshatra) are divided into 3 parts- Aadi (Vata) Nadi, Madhya (Pitta) Nadi and Antya (Kapha) Nadi." },
                    ]
                },
                {
                    id: 3, title: "",
                    kundaliMatchP: [
                        { descText: "Altogether, it is believed that at least 18 gunas must match for the marriage to be a fruitful one. The more the Guns match, the merrier it is for the couple. However, at times when Kundli does not match, an astrologer shall study the position of the planets and the reasons behind the lack of compatibility, and based on the same, can help you with remedies to solve the problem related to the lack of Gun Milan." },
                        { descText: "Besides anything else, Kundli matching also helps in finding if the matchmaking will lead to Nandi dosha in the life of the couple. For the unaware, Nadi Dosha deals with the difficulty in childbirth, which is one of the main reasons for dissatisfaction in the family. If Madhya Nandi dosha is prevalent, the couple may not be able to conceive. The reason: Nadi indicates Prakriti (basic element) of a person. These Prakritis are Vaata, Pitta and Kapha. If both the partners are born with the same Prakriti, the points obtained under Nadi would be zero and thus hampers child birth." },
                        { descText: "Hence it is always better to opt for gun milan or kundli milan so you know well in advance the problems that could come in your relationship and also the solutions to the same." },
                        { descText: "How to avail kundli match" },

                    ],
                    aboutLists: [
                        { id: 1, listItems: "" },

                    ]
                },

            ],
        };

    }

    render() {
        return (
            <React.Fragment>
                <main>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item md={9} xs={12}>
                                <HorizantalTopBanner />
                                <Box sx={{ flexGrow: 1 }} className='astrology-section'>
                                    <Grid container spacing={12} className='mb-45'>
                                        <Grid item md={7}>
                                            <Typography variant="h4" className='heading-title text-capitalize'>{this.state.title}</Typography>
                                            {
                                                this.state.textData.map((textP, index) => {
                                                    return <Typography variant='body1' key={index} className='text-justify'>
                                                        {textP.descText}
                                                    </Typography>
                                                })
                                            }
                                        </Grid>
                                        <Grid item md={5}>
                                            <div className='d-flex align-items-center h-100'>
                                                <img src={kundaliImg} alt="kundali" width={350} height={276} style={{ maxWidth: "100%", height: "auto" }} />
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Box>

                                <Box sx={{ flexGrow: 1, height: "auto" }} className='kundali-bgbox mt-16'>
                                    <KundaliMatchingComponent />
                                </Box>
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <SidebarCardComponent />
                                <VerticalAppBanner />
                            </Grid>
                        </Grid>
                    </Box>
                    <AstrologerComponent />
                    <Box sx={{ flexGrow: 1 }} className='horoscope-details-about mt-16'>
                        <Grid container spacing={2} className='mb-4'>
                            <Grid item md={12}>
                                <Typography variant="h3" className='heading-title'>{this.state.createKundaliTitle}</Typography>
                                {
                                    this.state.kundaliMatchData.map((aboutData) => {
                                        return <div className='details-about-wrap' key={aboutData.id}>
                                            <Typography variant="h6" className='sub-heading'>{aboutData.title}</Typography>
                                            {
                                                aboutData.kundaliMatchP.map((descP, index) => {
                                                    return <Typography variant='body1' key={index}>
                                                        {descP.descText}
                                                    </Typography>
                                                })
                                            }

                                            <ul>
                                                {
                                                    aboutData.aboutLists.map((aboutListItem) => {
                                                        return <li key={aboutListItem.id}>{aboutListItem.listItems}</li>
                                                    })
                                                }
                                            </ul>


                                        </div>
                                    })
                                }
                            </Grid>
                        </Grid>
                    </Box>
                </main>
            </React.Fragment>
        );
    }
}

export default KundaliMatchListing;