import * as React from 'react';
import "./../marriage-compatibility/marriageCompatibility.css";
import HorizantalTopBanner from '../appBanners/HorizantalTopBanner';
import SidebarCardComponent from '../sidebarCard/SidebarCardComponent';
import VerticalAppBanner from '../appBanners/VerticalAppBanner';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import heroImgthumb from "./../../images/marriage4x1.svg";
import aries from "./../../icons/h-aries.svg";
import taurus from "./../../icons/h-taurus.svg";
import gemini from "./../../icons/gemini.svg";
import cancer from "./../../icons/cancer.svg";
import leo from "./../../icons/leo.svg";
import virgo from "./../../icons/virgo.svg";
import libra from "./../../icons/libra.svg";
import scorpio from "./../../icons/scorpio.svg";
import sagittarius from "./../../icons/sagittarius.svg";
import capricorn from "./../../icons/capricorn.svg";
import aquarius from "./../../icons/aquarius.svg";
import pisces from "./../../icons/pisces.svg";
import { Button } from 'react-bootstrap';


interface MarriageCompatibilityProps {

}

interface MarriageCompatibilityState {
    title: String;
    textData: Array<MarriageCompatibilityDesc>;
    heroImg: any;
    heroImgTitle: any;
    marriageSign: Array<MarriageSign>;
    value: any
}

interface MarriageCompatibilityDesc {
    descText: String;
}
interface MarriageSign {
    title: String;
    card: Array<MarriageSignCard>;

}
interface MarriageSignCard {
    thumbIcon: any;
    thumbTitle: any;
    name: any;
    date: String;
}



class MarriageCompatibility extends React.Component<MarriageCompatibilityProps, MarriageCompatibilityState> {
    constructor(props: MarriageCompatibilityProps) {
        super(props);
        this.state = {
            title: "Marriage Compatibility",
            heroImg: heroImgthumb,
            heroImgTitle: "Marriage Compatibility",
            value: "",
            textData: [
                { descText: "You don't always get along like a blaze on flames with people, but when you're with that 'pecial person,' you feel happy and in control of the situation. We encounter numerous people throughout life. One person would be your life partner out of all those who may be terrific friends or mentors for you. You must make the appropriate choice for that person. They must make you feel at home, never depressed or too uncared for." },
                { descText: "Do you believe your heart might have jumped a beat if you had met that particular someone? If so, find out what your Sun sign conspires to have you do by checking your zodiac sign love compatibility." },
                { descText: "Zodiac sign compatibility reveals more than just compatibility in romantic relationships. You can also find information on your partner's and your own zodiac love and sexual compatibility. This can ensure a long-lasting relationship with shared understanding while also assisting you in learning further about your mate and your bond." },
                { descText: "Love compatibility can also forecast how your relationship will develop in the future, in addition to letting you know how things stand right now. Moreover, it reveals the strength of your current bond, what makes it successful, and if you and your loved one are about to experience harmony or conflict in the future. Hence, you may determine whether your connection is likely to progress in the ways you want by simply entering the appropriate zodiac sign. Kudos if your sign and your partner's sign align! Seamless times are predictable in advance." },

            ],
            marriageSign: [
                {
                    title: "Choose your sign",
                    card: [
                        { thumbIcon: aries, thumbTitle: "aries", name: "Aries", date: "Mar 21 - Apr 19" },
                        { thumbIcon: taurus, thumbTitle: "taurus", name: "Taurus", date: "Apr 20 - May 20" },
                        { thumbIcon: aries, thumbTitle: "gemini", name: "Gemini", date: "May 21 - Jun 21" },
                        { thumbIcon: cancer, thumbTitle: "cancer", name: "Cancer", date: "Jun 22 - Jul 22" },
                        { thumbIcon: leo, thumbTitle: "leo", name: "Leo", date: "Jul 23 - Aug 23" },
                        { thumbIcon: virgo, thumbTitle: "Virgo", name: "Virgo", date: "Aug 23 - Sep 22" },
                        { thumbIcon: libra, thumbTitle: "Libra", name: "Libra", date: "Jun 22 - Jul 22" },
                        { thumbIcon: scorpio, thumbTitle: "Scorpio", name: "Scorpio", date: "Oct 24 - Nov 21" },
                        { thumbIcon: sagittarius, thumbTitle: "Sagittarius", name: "Sagittarius", date: "Nov 22 - Dec 21" },
                        { thumbIcon: capricorn, thumbTitle: "Capricorn", name: "Capricorn", date: "Jun 22 - Jul 22" },
                        { thumbIcon: aquarius, thumbTitle: "Aquarius", name: "Aquarius", date: "Jun 22 - Jul 22" },
                        { thumbIcon: pisces, thumbTitle: "Pisces", name: "Pisces", date: "Jun 22 - Jul 22" },
                    ],
                }
            ],
        };
    }
    handleChoosePartner = ((event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();

    })
    // handleProductChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     event.preventDefault();
    //     this.setState({
    //         value: event.target.value
    //     });
    // };
    render() {
        return (
            <React.Fragment>
                <main>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item md={9} xs={12}>
                                <HorizantalTopBanner />
                                <Box sx={{ flexGrow: 1 }} className='astrology-section'>
                                    <Grid container spacing={{ xs: 2, lg: 7 }} className='mb-45'>
                                        <Grid item md={7}>
                                            <Typography variant="h4" className='heading-title'>
                                                {this.state.title}
                                            </Typography>
                                            {
                                                this.state.textData.map((textP, index) => {
                                                    return <Typography variant='body1' key={index} className='text-justify'>
                                                        {textP.descText}
                                                    </Typography>
                                                })
                                            }
                                        </Grid>
                                        <Grid item md={5}>
                                            <div className='d-flex align-items-center h-100'>
                                                <img src={this.state.heroImg} alt={this.state.heroImgTitle} width={327} height={350} style={{ maxWidth: "100%", height: "auto" }} />
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box sx={{ width: '100%' }} className='mt-16'>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <div className='marriage-sign-box'>
                                                {
                                                    this.state.marriageSign.map((marriage, index) => {
                                                        return <React.Fragment key={index}>
                                                            <Typography variant="h4" className='heading-2 text-center pb-4'>{marriage.title}</Typography>
                                                            <div className='sign-box-row'>
                                                                {
                                                                    marriage.card.map((cardItems, index) => {
                                                                        return <label className='sign-card' key={index}>
                                                                            <input type="radio" name="products" value={cardItems.name} className="card-input-element" />
                                                                            <div className="card-input">
                                                                                <div className='horo-icon-1x1'><img src={cardItems.thumbIcon} alt={cardItems.thumbTitle} /></div>
                                                                                <div className="signcard-body">
                                                                                    <h6>{cardItems.name}</h6>
                                                                                    <p>{cardItems.date}</p>
                                                                                </div>
                                                                            </div>
                                                                        </label>
                                                                    })
                                                                }
                                                            </div>
                                                        </React.Fragment>
                                                    })
                                                }
                                                <div className='d-flex justify-content-center mt-4'>
                                                    <Button className='sign-btn' onClick={this.handleChoosePartner}>Choose Your Partner Sign</Button>
                                                </div>
                                            </div>
                                        </Grid>

                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <SidebarCardComponent />
                                <VerticalAppBanner />
                            </Grid>
                        </Grid>
                    </Box>
                </main>
            </React.Fragment>
        );
    }
}

export default MarriageCompatibility;
