import * as React from 'react';
import "./../festival-details/festivalDetails.css";
import HorizantalTopBanner from '../appBanners/HorizantalTopBanner';
import SidebarCardComponent from '../sidebarCard/SidebarCardComponent';
import VerticalAppBanner from '../appBanners/VerticalAppBanner';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Link, Typography, Breadcrumbs } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import festivalHeroImg from "./../../images/kites.png";
import appBannerImg from "./../../images/app-banner-2-1083x261.svg";
import facebook from '../../icons/facebook.svg';
import twitter from '../../icons/twitter.svg';
import instagram from '../../icons/instagram.svg';
import youTube from '../../icons/youtube.svg';
import Table from 'react-bootstrap/Table';

interface FestivalDetailsProps {

}

interface FestivalDetailsState {
    title: String;
    heroImg: any;
    socialIcons: Array<SocialIconsState>;
    synopsis: Array<TextPara>;
    festivalTable: Array<FestivalTable>;
    bannerAds: any;
    bannerLink: any;
    festivalBody: Array<FestivalBody>;
}
interface SocialIconsState {
    actionLink: any;
    icons: any;
    title: any;
}
interface TextPara {
    data: String;
}
interface FestivalTable {
    title: String;
    tableData: Array<FestivalTableData>;

}
interface FestivalTableData {
    name: String;
    data: String;
}
interface FestivalBody {
    title: String;
    bodyPara: Array<TextPara>;
    listItems: Array<TextPara>;
}

class FestivalDetails extends React.Component<FestivalDetailsProps, FestivalDetailsState> {
    constructor(props: FestivalDetailsProps) {
        super(props);
        this.state = {
            title: "Makar Sankranti 2023",
            heroImg: festivalHeroImg,
            socialIcons: [
                { icons: facebook, title: "facebook", actionLink: "facebook" },
                { icons: twitter, title: "twitter", actionLink: "twitter" },
                { icons: instagram, title: "instagram", actionLink: "instagram" },
                { icons: youTube, title: "youTube", actionLink: "youTube" },
            ],
            synopsis: [
                { data: "Across the country, people celebrate Makar Sankranti every year with great pomp. Unlike most Hindu festivals, which depend on the Moon's changing position and the lunar calendar, Makar Sankranti 2023 date and timing also depend on the solar calendar. Every year, Makar Sankranti marks the movement of the Sun in Makar Rashi or Capricorn zodiac sign. In fact, the literal meaning of the word 'Sankranti' is 'movement'. Hence, such a name." },
                { data: "Also, on the day of the Makar Sankranti festival, the day-night duration is equal. Also, this festival officially marks the beginning of spring or the Indian summer. After this day, the Sun stays up a little longer than on previous days, making the days longer than the nights. Along with this, the festival of Makar Sankranti has great importance in Indian culture. Being a solar phenomenon too, it falls on the same date every year in the Gregorian calendar. In different parts of the country, people know it by different names. It is Pedda Panduga in Andhara Pradesh, Pongal in Tamil Nadu, Magha Bihu in Assam, Uttarayana in Gujrat, and much more." },
                { data: "Let us discuss know more about the vibrant festival of Makar Sankranti in 2023" }
            ],
            festivalTable: [
                {
                    title: "Makar Sankranti 2024",
                    tableData: [
                        { name: "Makar Sankranti Date", data: "15 January 2024, Saturday" },
                        { name: "Punya Kaal", data: "07:15 AM to 05:46 PM (10hr 55min)" },
                        { name: "Mahapunya Kaal", data: "07:15 AM to 09:00 PM (1hr 49min)" },
                    ]
                }
            ],
            bannerAds: appBannerImg,
            bannerLink: "home",
            festivalBody: [
                {
                    title: "What to do on 2024 Makar Sankaranti?",
                    bodyPara: [
                        { data: "On Makar Sankranti, it is customary to eat 'til-jaggery' and enjoy a joyous kite flying. People eat til-gur or sesame and jaggery in the form of laddoos or chikki. On this day, natives move forward with a positive attitude towards others, leaving behind the past of unpleasant relationships and sour-sweet memories. Also, on Makar Sankranti, people consume sweets to bring sweetness to their speech and behavior, which helps them to reduce enmity and praise the love and happiness around them." },
                        { data: "Sweets are also distributed on this festival to celebrate the journey of Lord Surya to meet his son, Lord Shani, keeping away the wrath of Lord Surya. Similarly, kite flying on Makar Sankranti also has its importance. In earlier days, kite flying was usually done in the morning, just after sunrise, when the Sun rays were not unbearable." },
                        { data: "The weather is usually quite cold during Makar Sankranti, so basking in the Sun for a bit while indulging in a joyous session of kite flying would be a good way to stay warm and stay away from infections and harmful bacteria." },
                    ],
                    listItems: [
                        { data: "" }
                    ]
                },
                {
                    title: "What rituals to follow on Makar Sankranti in 2023?",
                    bodyPara: [
                        { data: "The weather is usually quite cold during Makar Sankranti, so basking in the Sun for a bit while indulging in a joyous session of kite flying would be a good way to stay warm and stay away from infections and harmful bacteria." },
                    ],
                    listItems: [
                        { data: "Start your day by cleaning the house, especially the prayer place." },
                        { data: "Take bath and get ready for Puja for Makar Sankranti." },
                        { data: "While praying, you should apply tilak of rice flour and tie a roll on your hand." },
                        { data: "In a thali, you can keep ghevar, til laddu, and any other offering you are willing to donate." },
                        { data: "Also, sprinkle rice on the donated items." },
                        { data: "Plus, worship the Sun God by lighting a lamp and chanting the Surya Mantra 12 times." },
                    ]
                },
                {
                    title: "",
                    bodyPara: [
                        { data: "The celebration varies from state to state and region to region. The celebrations are varied, the dishes prepared are different, and the ways of celebrating the festival are varied, yet the spirit and enthusiasm among the people remain the same. Such is the beauty and wonder of this festival associated with our most beloved Sun God." },
                    ],
                    listItems: [
                        { data: "" },
                    ]
                },
                {
                    title: "Astrological significance behind Sankranti",
                    bodyPara: [
                        { data: "According to Hindu calendar, sun transits from one zodiac sign to another twice in a year. This change is known as Uttarayan or Dakshinayan. In Hinduism, the transit of Sun from south to north is meant to be very auspicious. When the Sun transits from East to South, the rays during this period is meant to be harmful. But when the Sun moves from East to North, the rays signifies the improvement in health and peace in life. When the period of Uttarayan is about to begin, the Sun transits from Gemini to Capricorn. It is considered that it is the starting of new morning of hopes. From this day, the days are likely to be longer whereas nights become shorter." },
                    ],
                    listItems: [
                        { data: "" },
                    ]
                }
            ],
        };
    }
    render() {
        return (
            <React.Fragment>
                <main>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item md={9} xs={12}>
                                <HorizantalTopBanner />
                                <Box sx={{ width: '100%' }} className="festival-details-wrap mt-16">
                                    <Typography variant='h4' className='heading-wtbg' textAlign="left">{this.state.title}</Typography>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} className='blog-details'>
                                            <Typography component="div" className='hero-img'>
                                                <img src={this.state.heroImg} alt="hero" width={896} height={504} />
                                            </Typography>
                                            <div className='blog-social-wrap'>
                                                <ul className='social-icons'>
                                                    {
                                                        this.state.socialIcons.map((socialIcon, index) => {
                                                            return <li key={index}>
                                                                <Link href={socialIcon.actionLink}><img src={socialIcon.icons} width={28} height={28} alt={socialIcon.title} /></Link>
                                                            </li>
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                            <div className='synopsis'>
                                                {
                                                    this.state.synopsis.map((data, index) => {
                                                        return <Typography variant='body1' key={index}>
                                                            {data.data}
                                                        </Typography>
                                                    })
                                                }
                                            </div>
                                        </Grid>
                                        <Grid item md={12} className='festival-table'>
                                            {
                                                this.state.festivalTable.map((festivalTable, index) => {
                                                    return <div key={index}>
                                                        <h3 className='table-heading'>{festivalTable.title}</h3>
                                                        <Table striped hover responsive>
                                                            <tbody>
                                                                {
                                                                    festivalTable.tableData.map((tableData, index) => {
                                                                        return <tr key={index}>
                                                                            <td>{tableData.name}</td>
                                                                            <td>{tableData.data}</td>
                                                                        </tr>
                                                                    })
                                                                }
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                })
                                            }
                                        </Grid>
                                        <Grid item md={12}>
                                            <div className='blog-app-banner'>
                                                <Link href={this.state.bannerLink} target="_blank">
                                                    <img src={this.state.bannerAds} alt="banner ads" width={896} height={217} />
                                                </Link>
                                            </div>
                                        </Grid>
                                        <Grid item md={12}>
                                            <div className='festival-details-body'>
                                                {
                                                    this.state.festivalBody.map((bodyData, index) => {
                                                        return <React.Fragment key={index}>
                                                            <h4 className='title'>{bodyData.title}</h4>
                                                            {
                                                                bodyData.bodyPara.map((bodyPara, index) => {
                                                                    return <p key={index}>{bodyPara.data}</p>
                                                                })
                                                            }

                                                            <ul>
                                                                {
                                                                    bodyData.listItems.map((listItems, index) => {
                                                                        return <li key={index}>{listItems.data}</li>
                                                                    })
                                                                }
                                                            </ul>
                                                        </React.Fragment>
                                                    })
                                                }
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <SidebarCardComponent />
                                <VerticalAppBanner />
                            </Grid>
                        </Grid>
                    </Box>
                </main>
            </React.Fragment>
        );
    }
}

export default FestivalDetails;
